/* eslint-disable no-unreachable */
import { lazy, Suspense, useEffect, useState } from "react";

import { Flex } from "@chakra-ui/react";
import { isEqual } from "lodash-es";
import { Route, Switch, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";

import Loading from "components/loading";
import { useIsRootPage, useQuery } from "hooks";
import PageWrapper from "layout/PageWrapper";
import { LinkedInTracking } from "LinkedinTracking";
import recoilState from "recoilState";

const CampaignReferral = lazy(() => import("pages/CampaignReferral"));
const CashLoan = lazy(() => import("pages/CashLoan"));
const RedirectPage = lazy(() => import("pages/CashLoan/Redirect"));
const RootLanding = lazy(() => import("pages/RootLanding"));
const Branch = lazy(
  () => import("pages/Sections/DisbursementNew/Disbursement/Branch")
);
const Courier = lazy(
  () => import("pages/Sections/DisbursementNew/Disbursement/Courier")
);
const DebitCard = lazy(
  () => import("pages/Sections/DisbursementNew/Disbursement/DebitCard")
);
const TamkartOfferConfirmation = lazy(
  () =>
    import(
      "pages/Sections/OfferSection/Offer/TamkartOffer/TamkartOfferConfirmation"
    )
);
const RequestWrapper = lazy(
  () => import("pages/Sections/Request/RequestWrapper")
);
const TamKart = lazy(() => import("pages/Tamkart"));

const appRoutes = [
  "/cash-loan",
  "/tamkart",
  "/cash-loan/paralel",
  "/cash-loan/refinance",
  "/cash-loan/refinance/approve"
];
const App = () => {
  const history = useHistory();
  const isRootPage = useIsRootPage();
  const query = useQuery();
  const [activePath, setActivePath] = useRecoilState(
    recoilState["cashLoan:activeStepPathAtom"]
  );
  const [registerQuery, setRegisterQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const { search, pathname } = history.location;
  const pathnameWithoutLocale = pathname.replace("/en", "").replace("/ru", "");
  useEffect(() => {
    if (pathnameWithoutLocale === "/tamkartDebet") {
      setLoading(false);
      setActivePath([5, 10]);

      return;
    }
    if (!isRootPage && !appRoutes.includes(pathnameWithoutLocale)) {
      history.replace("/");
    }
    const params = new URLSearchParams(search);
    if (params.has("register")) {
      setRegisterQuery(params.get("register") || "");
    }
    if (
      params.has("utm_referral") &&
      params.has("utm_branch") &&
      !params.has("utm_isReferral")
    ) {
      const utmBranchValue = params.get("utm_branch");
      history.replace({
        pathname: `/referral?utm_branch=${utmBranchValue}`
      });
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (registerQuery) {
    return (
      <div
        className="bg-white relative h-[100vh] app-wrapper flex flex-col overflow-auto"
        id="abb-app-wrapper"
      >
        <RequestWrapper
          setRegister={setRegisterQuery}
          renderPersonalInfo={(handleContinueFromPersonalInfo) => (
            <RedirectPage
              register={registerQuery}
              setRegister={setRegisterQuery}
              handleContinue={handleContinueFromPersonalInfo}
            />
          )}
        />
      </div>
    );
  }

  const isRootUrl =
    pathname === "/" || pathname === "/en" || pathname === "/ru";

  if ((query.get("referralCode") || query.get("webLink")) && isRootUrl) {
    return (
      <PageWrapper>
        <CampaignReferral />
      </PageWrapper>
    );
  }

  if (activePath[0] === 0 && activePath[1] === 0) {
    return <RootLanding />;
  }
  if (loading) {
    return <Loading opacity={0} />;
  }

  return (
    <Flex
      direction="column"
      pos="relative"
      overflow="auto"
      bgColor="white"
      h="100vh"
      bgAttachment="fixed"
      bgRepeat="no-repeat"
      bgPos="center"
      className="app-wrapper bg-origin-border md:bg-origin-padding"
      id="abb-app-wrapper"
    >
      {isEqual(activePath, [0, 2]) && <LinkedInTracking />}
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path={["/", "/ru", "/en"]}>
            <RootLanding />
          </Route>
          <Route exact path="*/branch-selection">
            <PageWrapper>
              <Branch />
            </PageWrapper>
          </Route>
          <Route exact path="*/courier-selection">
            <PageWrapper>
              <Courier />
            </PageWrapper>
          </Route>
          <Route exact path="*/debit-card-selection">
            <PageWrapper>
              <DebitCard />
            </PageWrapper>
          </Route>
          <Route
            exact
            path={[
              "*/cash-loan",
              "*/cash-loan/en",
              "*/cash-loan/refinance",
              "*/cash-loan/paralel",
              "*/cash-loan/refinance/approve",
              "*/cash-loan/approve",
              "*/cash-loan/cross-sell",
              "*/cash-loan/cross-sell-offer",
              "*/cash-loan/cross-sell-approve",
              "*/cash-loan/cross-sell-card-selection"
            ]}
          >
            <PageWrapper>
              <CashLoan />
            </PageWrapper>
          </Route>
          <Route exact path="*/tamkart/offer-confirmation">
            <PageWrapper>
              <TamkartOfferConfirmation />
            </PageWrapper>
          </Route>
          <Route exact path={["*/tamkart", "*/tamkartDebet"]}>
            <PageWrapper>
              <TamKart />
            </PageWrapper>
          </Route>
        </Switch>
      </Suspense>
    </Flex>
  );
};
export default App;
