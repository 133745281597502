/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-cycle */

import classNames from 'classnames'
import {
  MouseEventHandler, ReactNode, useEffect, useRef,
} from 'react'
import scrollIntoView from 'scroll-into-view-if-needed'

export interface IOptionProps {
  selected?: boolean
  focused?: boolean
  children: ReactNode
  index?: number
  selectedOptionValue?: string
  value: string
  searchValue?: string
  // eslint-disable-next-line no-unused-vars
  setSelectedOptionValue?: (v: string) => void
  // eslint-disable-next-line no-unused-vars
  setFocusedOptionIndex?: (selfIndex: number) => void
  __TYPE?: string
}

export const Option = ({
  children,
  focused,
  index,
  setFocusedOptionIndex,
  setSelectedOptionValue,
  value,
  selectedOptionValue,
}: IOptionProps) => {
  const preventScrollForMouseFocus = useRef(false)
  const optionRef = useRef(document.createElement('li'))
  const isSelected = selectedOptionValue === value
  useEffect(() => {
    if (focused) {
      if (preventScrollForMouseFocus.current) {
        preventScrollForMouseFocus.current = false
        return
      }
      scrollIntoView(optionRef.current, {
        block: 'nearest',
        scrollMode: 'if-needed',
        boundary: document.getElementById('select-parent'),
      })
    }
  }, [focused])
  const handleMouseOver: MouseEventHandler<HTMLLIElement> = () => {
    if (setFocusedOptionIndex) {
      preventScrollForMouseFocus.current = true
      setTimeout(() => {
        preventScrollForMouseFocus.current = false
      }, 1000)
      setFocusedOptionIndex(index as number)
    }
  }
  const handleClick: MouseEventHandler<HTMLLIElement> = () => {
    if (setSelectedOptionValue) {
      setSelectedOptionValue(value)
    }
  }
  return (
    <li
      ref={optionRef}
      role="option"
      aria-selected={isSelected}
      className={classNames('flex cursor-pointer py-[1.7rem] px-[2.2rem]', {
        'bg-blue bg-opacity-10': focused,
        '!bg-blue !text-white': isSelected,
      })}
      onClick={handleClick}
      onMouseMove={handleMouseOver}
    >
      {children}
    </li>
  )
}

const __TYPE = 'Component/SelectInput/Option'
export const isOptionType = (key: string) => key === __TYPE

Option.defaultProps = {
  __TYPE,
}
