/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable max-len */
import { ReactNode, useEffect, useRef } from "react";

import { Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
// import { useTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";

import logo from "assets/logoBlue.svg";
import { HeadphoneIcon, PlayIcon } from "components/icons";
import { SelectLocale } from "layout/reusables";
import recoilState from "recoilState";

import { VideoModal } from "./VideoModal";

type IProps = {
  children?: ReactNode;
  isLanding: boolean;
};
const TopBar = ({ children, isLanding, ...rest }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [, setActivePath] = useRecoilState(
    recoilState["cashLoan:activeStepPathAtom"]
  );

  const returnToFunnel = useRecoilValue(
    recoilState["cashLoan:returnToFunnelAtom"]
  );
  const topRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/") {
      topRef?.current?.scrollIntoView?.({ block: "end" });
    }
  }, [pathname]);

  // const steps = useMemo(
  //   () => [
  //     {
  //       label: `${t("steps.step_request_label")}`
  //     },
  //     {
  //       label: `${t("steps.step_result_label")}`
  //     },
  //     {
  //       label: `${t("steps.step_disbursement_label")}`
  //     },
  //     {
  //       label: `${t("steps.step_agreement_label")}`
  //     }
  //   ],
  //   [t]
  // );

  // const showSteps =
  //   (activeStepPath[0] === 0 && activeStepPath[1] > 0) || activeStepPath[0] > 0;

  const goToMainActivePath = () => {
    setActivePath([0, 0]);
    // if (pathname.includes("tamkart")) {
    //   history.push("/tamkart");

    //   return;
    // }
    // if (pathname.includes("cash-loan")) {
    //   history.push("/cash-loan");

    //   return;
    // }
    history.push("/");
  };

  return (
    <>
      {returnToFunnel.showReturnToFunnel && (
        <VideoModal
          isOpen={isOpen}
          onClose={onClose}
          videoUrl={returnToFunnel.videoUrl}
        />
      )}
      <Flex
        py="1.2rem"
        {...(isLanding
          ? {
              px: {
                base: "1.6rem",
                sm: "3.2rem",
                md: "6rem"
                // lg: "12rem"
              },
              minH: {
                base: "6rem",
                sm: "9.6rem"
              }
            }
          : {
              px: {
                base: "1.6rem",
                md: "2.8rem"
              },
              bg: "white",
              zIndex: 10,
              left: 0,
              right: 0,
              pos: "fixed",
              minH: {
                base: "6rem",
                sm: "7.4rem"
              }
            })}
        {...rest}
      >
        <Flex
          w="100%"
          alignItems="center"
          mx="auto"
          pos="relative"
          justifyContent="space-between"
          {...(isLanding && {
            maxW: "104rem"
          })}
        >
          <Flex
            tabIndex={0}
            role="link"
            alignItems="center"
            cursor="pointer"
            minW="11rem"
            onKeyDown={goToMainActivePath}
            onClick={goToMainActivePath}
            className="cursor-pointer flex items-center min-w-[11rem]"
          >
            <Image ref={topRef} h="5rem" src={logo} alt="logo" />
          </Flex>
          {children}
          <Flex>
            <Flex h="100%" alignItems="center">
              <SelectLocale />
              <Flex alignItems="center" display={{ base: "none", md: "flex" }}>
                <HeadphoneIcon />
                <Text
                  fontSize="1.4rem"
                  fontWeight="500"
                  lineHeight="2rem"
                  textColor="#0D0D19"
                  display="flex"
                  ml="1rem"
                >
                  937
                </Text>
              </Flex>
            </Flex>
            {returnToFunnel.showReturnToFunnel && !!returnToFunnel.videoUrl && (
              <Flex
                borderRadius="16px"
                bgColor="fern.main"
                p="0px 16px 0px 12px"
                h="32px"
                alignItems="center"
                gap="6px"
                ml="16px"
                as="button"
                onClick={onOpen}
              >
                <PlayIcon />
                <Text fontSize="14px" fontWeight={500} color="white">
                  {t("button.video_instruction")}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default TopBar;
