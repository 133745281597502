/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function LightBulbIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width={`${32 * +scale}`}
      height={`${32 * +scale}`}
      viewBox={`0 0 ${32 * +scale} ${32 * +scale}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6.66658C10.8454 6.66658 6.66671 10.8453 6.66671 15.9999C6.66671 19.7231 8.84678 22.9371 12 24.4351V26.6667C12 28.8759 13.7909 30.6667 16 30.6667C18.2092 30.6667 20 28.8759 20 26.6667V24.4351C23.1533 22.9371 25.3334 19.7231 25.3334 15.9999C25.3334 10.8453 21.1547 6.66658 16 6.66658Z"
        fill="#2058BB"
        style={{ transform: `scale(${scale})` }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1.33325C16.7364 1.33325 17.3334 1.93021 17.3334 2.66659V3.99992C17.3334 4.7363 16.7364 5.33325 16 5.33325C15.2637 5.33325 14.6667 4.7363 14.6667 3.99992V2.66659C14.6667 1.93021 15.2637 1.33325 16 1.33325Z"
        fill="#2058BB"
        style={{ transform: `scale(${scale})` }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33337 15.9999C1.33337 15.2635 1.93033 14.6666 2.66671 14.6666H4.00004C4.73642 14.6666 5.33337 15.2635 5.33337 15.9999C5.33337 16.7363 4.73642 17.3333 4.00004 17.3333H2.66671C1.93033 17.3333 1.33337 16.7363 1.33337 15.9999Z"
        fill="#2058BB"
        style={{ transform: `scale(${scale})` }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.59043 5.59031C6.11113 5.06961 6.95535 5.06961 7.47605 5.59031L8.27618 6.39044C8.79688 6.91114 8.79688 7.75536 8.27618 8.27606C7.75548 8.79676 6.91126 8.79676 6.39056 8.27606L5.59043 7.47593C5.06974 6.95523 5.06974 6.11101 5.59043 5.59031Z"
        fill="#2058BB"
        style={{ transform: `scale(${scale})` }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.41 5.59043C26.9306 6.1112 26.9305 6.95542 26.4097 7.47605L25.6094 8.27618C25.0886 8.79681 24.2444 8.79671 23.7238 8.27594C23.2031 7.75518 23.2033 6.91096 23.724 6.39032L24.5243 5.59019C25.0451 5.06956 25.8893 5.06967 26.41 5.59043Z"
        fill="#2058BB"
        style={{ transform: `scale(${scale})` }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6667 15.9999C26.6667 15.2635 27.2637 14.6666 28 14.6666H29.3334C30.0698 14.6666 30.6667 15.2635 30.6667 15.9999C30.6667 16.7363 30.0698 17.3333 29.3334 17.3333H28C27.2637 17.3333 26.6667 16.7363 26.6667 15.9999Z"
        fill="#2058BB"
        style={{ transform: `scale(${scale})` }}
      />
    </svg>
  );
}
