import { atom, selector } from 'recoil'

export interface IpersonalInfoFormData {
  finNumber: string
  phoneNumber: string
  termsRead: boolean
  termsChecked: boolean
}

export const defaultPersonalInfoFormData: IpersonalInfoFormData = {
  finNumber: '',
  phoneNumber: '+994 __ ___-__-__',
  termsRead: true, // temp
  termsChecked: false,
}

export const personalInfoFormDataAtom = atom<IpersonalInfoFormData>({
  key: 'personalInfo:formData',
  default: defaultPersonalInfoFormData,
})

export const verificationTokenAtom = atom<string>({
  key: 'personalInfo:verificationTokenAtom',
  default: '',
})

export const requestOTPSelector = selector<boolean>({
  key: 'personalInfo:requestOTP',
  get: async (/* { get } */) => {
    // const formData = get(personalInfoFormDataAtom)
    await new Promise((res) => setTimeout(() => res('p1'), 4000))
    return true
  },
})

export default {
  'personalInfo:formData': personalInfoFormDataAtom,
  'personalInfo:requestOTP': requestOTPSelector,
  'personalInfo:verificationTokenAtom': verificationTokenAtom,
}
