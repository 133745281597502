import { extendTheme } from "@chakra-ui/react";

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-13px)"
};

const customizedThemeProperties = {
  colors: {
    background: {
      primary: "#F2F2F7",
      secondary: "#FFFFFF"
    },
    onBackground: {
      primary: "#0D0D19",
      secondary: "#646466",
      tertiary: "#A0A0A3",
      quaternary: "#D2D2D6"
    },
    surface: {
      primary: "#FFFFFF",
      secondary: "#F2F2F7",
      tertiary: "#EBEBF2",
      quaternary: "#E1E1EB"
    },
    onSurface: {
      primary: "#0D0D19",
      secondary: "#000000A3",
      tertiary: "#0000005C",
      quaternary: "#0000001F"
    },
    disable: {
      primary: "#E4E4E4",
      onDisable: "#00000080"
    },
    source: {
      black: "#000000",
      blackScrim: "#000000B8",
      white: "#FFFFFF",
      whiteScrim: "#FFFFFFB8",
      success: "#00A400",
      error: "#F32828",
      warning: "#FFA500",
      primaryAlpha: "#2058BB1F"
    },
    container: {
      primary: "#2058BB1F",
      secondary: "#3B8DD41F",
      black: "#0000001A",
      white: "#FFFFFF1A",
      warning: "#FFA5001A",
      error: "#00A4001A",
      success: "#00A4001A"
    },
    sme: {
      primary: "#1C105C"
    },
    abb: {
      primary: "#2058BB",
      secondary: "#3B8DD4"
    },
    tam: {
      primary: "#75FA56",
      secondary: "#131311",
      tertiary: "#D9F4D7"
    },
    abbTech: {
      primary: "#3030F2",
      secondary: "#8CA5FF"
    },
    red: {
      main: "#E52E2E",
      medium: "#FF4E4E",
      alpha: "#E52E2E24",
      light: "#FBE2E2"
    },
    pink: {
      main: "#DF2A5A",
      medium: "#E44E76",
      alpha: "#DF2A5A24",
      light: "#FBE1E8"
    },
    rubyRed: {
      main: "#DF2A5A",
      medium: "#E44E76",
      alpha: "#DF2A5A24",
      light: "#FBE1E8"
    },
    violetBlue: {
      main: "#4A5DBF",
      medium: "#6878CA",
      alpha: "#4A5DBF24",
      light: "#E6E8F6"
    },
    green: {
      main: "#06A822",
      medium: "#30B848",
      alpha: "#06A82224",
      light: "#DCF3E0"
    },
    fern: {
      main: "#60A961"
    }
  },
  fonts: {
    heading: "Inter Variable, sans-serif",
    body: "Inter Variable, sans-serif"
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              },
              input: {
                borderColor: "inherit",
                boxShadow: "none"
              }
            },
            input: {
              height: "56px",
              fontSize: "16px",
              px: "16px",
              paddingTop: "14px"
            },
            "input:not(:placeholder-shown) + label,.chakra-input__group:has(input:not(:placeholder-shown)) + label,.chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles
              },
            "input[aria-invalid=true], input[data-invalid]": {
              boxShadow: "none"
            },
            label: {
              top: "11px",
              left: "7px",
              zIndex: 2,
              position: "absolute",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
              fontSize: "16px",
              fontWeight: 400,
              color: "#0000005C"
            }
          }
        }
      }
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderRadius: "4px"
        }
      },
      sizes: {
        lg: {
          control: {
            w: "22px",
            h: "22px"
          },
          icon: {
            fontSize: "12px"
          },
          label: {
            marginLeft: "10px"
          }
        }
      }
    }
  }
};

export const customTheme = extendTheme(customizedThemeProperties);

export default customTheme;
