/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function MoneyIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.74984 0.333313C2.80432 0.333313 0.416504 2.72113 0.416504 5.66665V16.3333C0.416504 19.2788 2.80432 21.6666 5.74984 21.6666H21.7498C24.6954 21.6666 27.0832 19.2788 27.0832 16.3333V5.66665C27.0832 2.72113 24.6954 0.333313 21.7498 0.333313H5.74984ZM4.4165 3.33331C3.86422 3.33331 3.4165 3.78103 3.4165 4.33331C3.4165 4.8856 3.86422 5.33331 4.4165 5.33331H7.08317C7.63546 5.33331 8.08317 4.8856 8.08317 4.33331C8.08317 3.78103 7.63546 3.33331 7.08317 3.33331H4.4165ZM19.4165 17.6666C19.4165 17.1144 19.8642 16.6666 20.4165 16.6666H23.0832C23.6355 16.6666 24.0832 17.1144 24.0832 17.6666C24.0832 18.2189 23.6355 18.6666 23.0832 18.6666H20.4165C19.8642 18.6666 19.4165 18.2189 19.4165 17.6666ZM13.7498 13.6666C15.2226 13.6666 16.4165 12.4727 16.4165 11C16.4165 9.52722 15.2226 8.33331 13.7498 8.33331C12.2771 8.33331 11.0832 9.52722 11.0832 11C11.0832 12.4727 12.2771 13.6666 13.7498 13.6666Z"
        fill="#3B8DD4"
      />
    </svg>
  );
}
