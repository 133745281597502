import React from "react";

import {
  Box,
  BoxProps,
  Heading,
  HeadingProps,
  Text,
  TextProps
} from "@chakra-ui/react";

interface Props extends Omit<BoxProps, "title"> {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  titleProps?: HeadingProps;
  subtitleProps?: TextProps;
}

export const SectionTitle = ({
  title,
  subtitle,
  titleProps,
  subtitleProps,
  ...restProps
}: Props) => (
  <Box {...restProps}>
    <Heading
      textAlign="center"
      lineHeight={{ base: "4rem", md: "3.6rem" }}
      fontWeight={600}
      color="#0D0D19"
      fontSize={{ base: "2rem", md: "3rem" }}
      {...titleProps}
    >
      {title}
    </Heading>
    {subtitle && (
      <Text
        textAlign="center"
        fontWeight={400}
        lineHeight="2rem"
        mt="1rem"
        fontSize="1.6rem"
        {...subtitleProps}
      >
        {subtitle}
      </Text>
    )}
  </Box>
);
