/* eslint-disable react/function-component-definition */

export default function CreditCardPlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        d="M33.6669 25.4C33.6669 24.4795 32.9207 23.7333 32.0002 23.7333C31.0798 23.7333 30.3336 24.4795 30.3336 25.4V28.7333H27.0002C26.0798 28.7333 25.3336 29.4795 25.3336 30.4C25.3336 31.3205 26.0798 32.0667 27.0002 32.0667H30.3336V35.4C30.3336 36.3205 31.0798 37.0666 32.0002 37.0666C32.9207 37.0666 33.6669 36.3205 33.6669 35.4V32.0667H37.0002C37.9207 32.0667 38.6669 31.3205 38.6669 30.4C38.6669 29.4795 37.9207 28.7333 37.0002 28.7333H33.6669V25.4Z"
        fill="#2058BB"
      />
      <path
        d="M8.93602 7.06665L31.7312 7.06666C32.6097 7.06663 33.3677 7.0666 33.992 7.11761C34.6509 7.17144 35.3062 7.2903 35.9369 7.61163C36.8777 8.09099 37.6426 8.8559 38.122 9.79671C38.4433 10.4273 38.5621 11.0827 38.616 11.7416C38.667 12.3659 38.6669 13.1238 38.6669 14.0024C38.6669 14.7743 38.0412 15.4 37.2693 15.4H3.3979C2.626 15.4 2.00025 14.7742 2.00025 14.0023C2.00022 13.1238 2.0002 12.3658 2.0512 11.7416C2.10504 11.0827 2.2239 10.4273 2.54522 9.7967C3.02459 8.85589 3.78949 8.09099 4.7303 7.61162C5.36093 7.2903 6.0163 7.17144 6.67521 7.11761C7.29946 7.0666 8.0575 7.06663 8.93602 7.06665Z"
        fill="#2058BB"
      />
      <path
        d="M4.66691 18.7333C3.73349 18.7333 3.26678 18.7333 2.91026 18.915C2.59666 19.0748 2.34169 19.3297 2.1819 19.6433C2.00025 19.9999 2.00025 20.4666 2.00025 21.4V26.7976C2.00022 27.6761 2.0002 28.4341 2.0512 29.0584C2.10503 29.7173 2.22389 30.3726 2.54522 31.0033C3.02458 31.9441 3.78949 32.709 4.7303 33.1884C5.36093 33.5097 6.0163 33.6285 6.6752 33.6824C7.29939 33.7334 8.05718 33.7333 8.93558 33.7333L20.2907 33.7333C21.2067 33.7333 21.6647 33.7333 21.8545 33.6269C22.0816 33.4997 22.1251 33.45 22.2213 33.2082C22.3018 33.006 22.2159 32.3588 22.044 31.0644C22.0151 30.8471 22.0002 30.6253 22.0002 30.4C22.0002 27.6386 24.2388 25.4 27.0002 25.4C27.0002 22.6386 29.2388 20.4 32.0002 20.4C34.7617 20.4 37.0002 22.6386 37.0002 25.4C37.6532 25.4 37.9796 25.4 38.1169 25.3503C38.4024 25.2471 38.5141 25.1354 38.6173 24.85C38.6669 24.7127 38.6669 24.4703 38.6669 23.9855V21.4C38.6669 20.4666 38.6669 19.9999 38.4853 19.6433C38.3255 19.3297 38.0705 19.0748 37.7569 18.915C37.4004 18.7333 36.9337 18.7333 36.0002 18.7333H4.66691Z"
        fill="#2058BB"
      />
    </svg>
  );
}
