interface Iprops {
  color?: string
  scale?: string
  className?: string
}

export default function DownIcon({
  color = 'currentColor',
  className,
  scale = '1',
  ...props
}: Iprops) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      width={`${20 * +scale}`}
      height={`${12 * +scale}`}
      fill="none"
      className={className || ''}
      viewBox={`0 0 ${20 * +scale} ${12 * +scale}`}
      {...props}
    >
      <path
        style={{ transform: `scale(${scale})` }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L10 9.43934L18.4697 0.96967C18.7626 0.676777 19.2374 0.676777 19.5303 0.96967C19.8232 1.26256 19.8232 1.73744 19.5303 2.03033L10.5303 11.0303C10.2374 11.3232 9.76256 11.3232 9.46967 11.0303L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967Z"
        fill={color}
      />
    </svg>
  )
}
