/* eslint-disable indent */
interface Faq {
  titleLangKey: string;
  answersLangKey: string;
  descriptionLangKey?: string;
}

export const useGetFaqList = (isTamkart?: boolean) => {
  const baseLangKey = isTamkart ? "tamkart_faq.faq_" : "cash_landing.faq.faq_";

  const faqList: Faq[] = [
    {
      titleLangKey: `${baseLangKey}title_1`,
      answersLangKey: `${baseLangKey}content_1`
    },
    {
      titleLangKey: `${baseLangKey}title_2`,
      answersLangKey: `${baseLangKey}content_2`
    },
    {
      titleLangKey: `${baseLangKey}title_3`,
      answersLangKey: `${baseLangKey}content_3`,
      ...(isTamkart && { descriptionLangKey: `${baseLangKey}description_3` })
    },
    {
      titleLangKey: `${baseLangKey}title_4`,
      answersLangKey: `${baseLangKey}content_4`
    },
    {
      titleLangKey: `${baseLangKey}title_5`,
      answersLangKey: `${baseLangKey}content_5`
    },
    {
      titleLangKey: `${baseLangKey}title_6`,
      answersLangKey: `${baseLangKey}content_6`
    },
    ...(isTamkart
      ? []
      : [
          {
            titleLangKey: `${baseLangKey}title_7`,
            answersLangKey: `${baseLangKey}content_7`
          }
        ])
  ];

  return faqList;
};
