/* eslint-disable react/function-component-definition */

export default function ListItemBullet() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="4"
      viewBox="0 0 4 4"
      fill="none"
      style={{ marginRight: "0.8rem" }}
    >
      <circle cx="2" cy="2" r="2" fill="#2058BB" />
    </svg>
  );
}
