/* eslint-disable no-restricted-properties */
/* eslint-disable max-len */
import { Dispatch, SetStateAction } from 'react'
import { atom, selector } from 'recoil'

type CalculateMonthlyPaymentParams = {
  amount: number;
  term: number;
  interestRate: number;
  setCalculatedInterestRate: Dispatch<SetStateAction<number>>;
  alternativeRate?: {
    minTerm: number;
    maxTerm: number;
    interestRateAddition: number;
    hasInterestRateAddition: boolean;
  };
  maxMonthlyPayment45Dti?: number;
};

export const loanAmountAtom = atom<string>({
  key: 'loan:loanAmountAtom',
  default: '10000',
})

export const termInMonthsAtom = atom<string>({
  key: 'loan:termInMonthsAtom',
  default: '12',
})

export const rateAtom = atom<string>({
  key: 'loan:rateAtom',
  default: '19',
})

const calcMonthly = (amount: number, term: number, rate: number): number => {
  const percent = rate / 100 / 12
  const pow = Math.pow(1 + percent, term)
  return amount * ((percent * pow) / (pow - 1))
}

export const calculateMonthlyPayment = ({
  amount,
  interestRate,
  setCalculatedInterestRate,
  term,
  alternativeRate,
  maxMonthlyPayment45Dti,
}: CalculateMonthlyPaymentParams): string => {
  const rateAddition = alternativeRate?.interestRateAddition || 0

  let monthlyPayment = calcMonthly(amount, term, interestRate)
  let rate = interestRate

  if (alternativeRate?.hasInterestRateAddition) {
    const reducedMonthlyPayment = calcMonthly(
      amount,
      term,
      interestRate - rateAddition,
    )

    if (reducedMonthlyPayment <= (maxMonthlyPayment45Dti || 0)) {
      rate = interestRate - rateAddition
      monthlyPayment = reducedMonthlyPayment
    }
  } else if (monthlyPayment > (maxMonthlyPayment45Dti || 0)) {
    monthlyPayment = calcMonthly(amount, term, interestRate + rateAddition)
    rate = interestRate + rateAddition
  }
  setCalculatedInterestRate(Number((rate ?? 0).toFixed(1)))
  return monthlyPayment.toFixed(2)
}

export const calculateMaxAmountForTerm = (
  maxMonthlyPayment: number,
  term: number,
  interestRate: number,
): string => {
  const interestRatePerMonth = interestRate / 100 / 12
  // eslint-disable-next-line max-len
  const maxAmountForTerm = (maxMonthlyPayment / interestRatePerMonth)
    * (1 - 1 / (1 + interestRatePerMonth) ** term)
  const result = Math.trunc(maxAmountForTerm)
  return String(result)
}

// export const monthlyPaymentSelector = selector<string>({
//   key: 'loan:monthlyPaymentSelector',
//   get: ({ get }) => {
//     const amount = get(loanAmountAtom)
//     const term = get(termInMonthsAtom)
//     return calculateMonthlyPayment(Number(amount), Number(term), 18)
//   },
// })

export default {
  'loan:loanAmountAtom': loanAmountAtom,
  'loan:termInMonthsAtom': termInMonthsAtom,
  // 'loan:monthlyPaymentSelector': monthlyPaymentSelector,
  'loan:rateAtom': rateAtom,
}
