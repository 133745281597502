import { useCallback, useState } from "react";

import _ from "lodash-es";

export const useDebounce = (valueReceived: string, wait = 1000) => {
  const [state, setState] = useState(valueReceived);
  const debounce = useCallback(
    _.debounce((valueToDebounce) => {
      setState(valueToDebounce);
    }, wait),
    []
  );
  const setDebouncedState = (stateNext: string) => {
    debounce(stateNext);
  };

  return [state, setDebouncedState, setState] as const;
};
