/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function CameraIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="#3B8DD4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7438 1.33338C12.5969 1.33334 12.469 1.33331 12.3471 1.3407C10.8046 1.43411 9.45393 2.40763 8.87755 3.84139C8.83196 3.95478 8.79157 4.07606 8.74514 4.21546L8.73527 4.24508C8.67342 4.43064 8.66 4.46902 8.6486 4.49738C8.45647 4.9753 8.00624 5.29981 7.49209 5.33095C7.46195 5.33277 7.4167 5.33338 7.20763 5.33338L7.13315 5.33337C6.71334 5.33329 6.40489 5.33323 6.141 5.35937C3.61132 5.61001 1.61014 7.61119 1.3595 10.1409C1.33336 10.4047 1.33342 10.7008 1.33351 11.1005L1.33351 21.6551C1.3335 22.7284 1.33348 23.6142 1.39244 24.3358C1.45367 25.0853 1.58509 25.7742 1.91481 26.4213C2.42614 27.4249 3.24203 28.2408 4.24556 28.7521C4.89268 29.0818 5.58162 29.2132 6.33108 29.2745C7.05267 29.3334 7.93847 29.3334 9.01175 29.3334H22.9886C24.0619 29.3334 24.9477 29.3334 25.6693 29.2745C26.4187 29.2132 27.1077 29.0818 27.7548 28.7521C28.7583 28.2408 29.5742 27.4249 30.0855 26.4213C30.4153 25.7742 30.5467 25.0853 30.6079 24.3358C30.6669 23.6142 30.6669 22.7284 30.6668 21.6551L30.6669 11.1005C30.6669 10.7008 30.667 10.4047 30.6409 10.1409C30.3902 7.61119 28.389 5.61001 25.8594 5.35937C25.5955 5.33322 25.287 5.33329 24.8672 5.33337L24.7927 5.33338C24.5837 5.33338 24.5384 5.33277 24.5083 5.33095C23.9941 5.29981 23.5439 4.9753 23.3518 4.49738C23.3404 4.46902 23.3269 4.43064 23.2651 4.24508L23.2552 4.21545C23.2088 4.07609 23.1684 3.95475 23.1228 3.84139C22.5464 2.40763 21.1957 1.43411 19.6533 1.3407C19.5313 1.33331 19.4035 1.33334 19.2566 1.33338H12.7438ZM21.3335 16.6667C21.3335 19.6122 18.9457 22 16.0002 22C13.0546 22 10.6668 19.6122 10.6668 16.6667C10.6668 13.7212 13.0546 11.3334 16.0002 11.3334C18.9457 11.3334 21.3335 13.7212 21.3335 16.6667Z"
        fill="#3B8DD4"
      />
    </svg>
  );
}
