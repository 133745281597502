/* eslint-disable react/function-component-definition */

export default function DropdownDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.61972 10.2619C8.40281 10.4855 8.29436 10.5972 8.1693 10.6391C8.05929 10.676 7.94079 10.676 7.83078 10.6391C7.70572 10.5972 7.59727 10.4855 7.38036 10.2619L5.38815 8.20864C4.91889 7.72499 4.68425 7.48317 4.6684 7.27555C4.65464 7.0954 4.72539 6.91936 4.85872 6.802C5.01237 6.66675 5.34419 6.66675 6.00783 6.66675L9.99225 6.66675C10.6559 6.66675 10.9877 6.66675 11.1414 6.802C11.2747 6.91936 11.3454 7.0954 11.3317 7.27555C11.3158 7.48317 11.0812 7.72499 10.6119 8.20864L8.61972 10.2619Z"
        fill="black"
        fillOpacity="0.36"
      />
    </svg>
  );
}
