import cashLoan from "./cashLoan";
import shared from "./shared";
import tamkart from "./tamkart";

/**
 * The available `atoms` and `selectors` of your project.
 */
const recoilState = <const>{
  ...cashLoan,
  ...tamkart,
  ...shared
};

export default recoilState;

export type recoilStateKeys = keyof typeof recoilState;
