import React from "react";

import { useRadio, Box, UseRadioProps, Flex, Text } from "@chakra-ui/react";

const CheckedIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5312_36203)">
      <rect width="20" height="20" rx="10" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#2058BB"
      />
      <circle cx="10" cy="10" r="6" fill="#2058BB" />
    </g>
  </svg>
);

const UncheckedIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5312_36143)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#E1E1EB"
      />
    </g>
  </svg>
);

interface RadioCardProps extends UseRadioProps {
  children?: React.ReactNode;
}

export const Radio = ({ children, ...props }: RadioCardProps) => {
  const { getInputProps, getCheckboxProps, state } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" display="inline-block">
      <input {...input} style={{ display: "none" }} />
      <Flex {...checkbox} gap="8px" alignItems="center">
        {state.isChecked ? <CheckedIcon /> : <UncheckedIcon />}
        <Text fontWeight={500} lineHeight="24px">{children}</Text>
      </Flex>
    </Box>
  );
};
