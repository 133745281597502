import { atom } from "recoil";

export type CardType = "default" | "gold" | "premium" | "platinium";

export const selectedCardCodeAtom = atom<CardType | "">({
  key: "tamkart:selectedCardCodeAtom",
  default: "default"
});

export default {
  "tamkart:selectedCardCodeAtom": selectedCardCodeAtom
};
