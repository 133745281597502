import { atom } from 'recoil'

export const contractDocsReadAtom = atom<boolean>({
  key: 'contracts:contractDocsReadAtom',
  default: false,
})

export interface IContract {
  fileName: string
  fileNetId: string
  fileSize: string
  content: string
}
export interface IFatcaQuestion {
  questionId: number
  questionText: string
}

export const contractListAtom = atom<IContract[]>({
  key: 'contracts:contractListAtom',
  default: [],
})

export default {
  'contracts:contractDocsReadAtom': contractDocsReadAtom,
  'contracts:contractListAtom': contractListAtom,
}
