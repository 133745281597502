/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function QrCodeIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8787 1.33399C10.7104 1.33397 10.5465 1.33395 10.4078 1.34528C10.2554 1.35774 10.0756 1.38711 9.8946 1.47931C9.64372 1.60714 9.43974 1.81112 9.31191 2.062C9.21972 2.24295 9.19034 2.42277 9.17789 2.57517C9.16655 2.71393 9.16657 2.87777 9.16659 3.04601V5.62192C9.16657 5.79016 9.16655 5.95405 9.17789 6.09281C9.19034 6.2452 9.21972 6.42503 9.31191 6.60598C9.43974 6.85686 9.64372 7.06083 9.8946 7.18866C10.0756 7.28086 10.2554 7.31024 10.4078 7.32269C10.5465 7.33403 10.7104 7.33401 10.8787 7.33399H13.4545C13.6228 7.33401 13.7866 7.33403 13.9254 7.32269C14.0778 7.31024 14.2576 7.28086 14.4386 7.18866C14.6895 7.06083 14.8934 6.85686 15.0213 6.60598C15.1135 6.42503 15.1428 6.2452 15.1553 6.09281C15.1666 5.95406 15.1666 5.79024 15.1666 5.62203V3.04607C15.1666 2.87785 15.1666 2.71391 15.1553 2.57517C15.1428 2.42277 15.1135 2.24295 15.0213 2.062C14.8934 1.81112 14.6895 1.60714 14.4386 1.47931C14.2576 1.38711 14.0778 1.35774 13.9254 1.34528C13.7866 1.33395 13.6228 1.33397 13.4545 1.33399H10.8787ZM11.4999 4.33398C11.4999 3.96579 11.7984 3.66732 12.1666 3.66732H12.1733C12.5414 3.66732 12.8399 3.96579 12.8399 4.33398C12.8399 4.70217 12.5414 5.00065 12.1733 5.00065H12.1666C11.7984 5.00065 11.4999 4.70217 11.4999 4.33398Z"
        fill="#2058BB"
      />
      <path
        d="M9.16659 8.00065C8.7984 8.00065 8.49992 8.29913 8.49992 8.66732C8.49992 9.03551 8.7984 9.33399 9.16659 9.33399H9.17326C9.54144 9.33399 9.83992 9.03551 9.83992 8.66732C9.83992 8.29913 9.54144 8.00065 9.17326 8.00065H9.16659Z"
        fill="#2058BB"
      />
      <path
        d="M12.1666 11.0007C11.7984 11.0007 11.4999 11.2991 11.4999 11.6673C11.4999 12.0355 11.7984 12.334 12.1666 12.334H12.1733C12.5414 12.334 12.8399 12.0355 12.8399 11.6673C12.8399 11.2991 12.5414 11.0007 12.1733 11.0007H12.1666Z"
        fill="#2058BB"
      />
      <path
        d="M9.83326 10.334C10.2014 10.334 10.4999 10.6325 10.4999 11.0007V14.0007C10.4999 14.3688 10.2014 14.6673 9.83326 14.6673C9.46507 14.6673 9.16659 14.3688 9.16659 14.0007V11.0007C9.16659 10.6325 9.46507 10.334 9.83326 10.334Z"
        fill="#2058BB"
      />
      <path
        d="M11.4999 8.66732C11.1317 8.66732 10.8333 8.9658 10.8333 9.33399C10.8333 9.70218 11.1317 10.0007 11.4999 10.0007H14.4999C14.8681 10.0007 15.1666 9.70218 15.1666 9.33399C15.1666 8.9658 14.8681 8.66732 14.4999 8.66732H11.4999Z"
        fill="#2058BB"
      />
      <path
        d="M14.4999 10.6673C14.8681 10.6673 15.1666 10.9658 15.1666 11.334V14.0007C15.1666 14.3688 14.8681 14.6673 14.4999 14.6673H11.8333C11.4651 14.6673 11.1666 14.3688 11.1666 14.0007C11.1666 13.6325 11.4651 13.334 11.8333 13.334H13.8333V11.334C13.8333 10.9658 14.1317 10.6673 14.4999 10.6673Z"
        fill="#2058BB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.54532 1.33399H6.12119C6.28944 1.33397 6.45331 1.33395 6.59208 1.34528C6.74447 1.35774 6.92429 1.38711 7.10524 1.47931C7.35613 1.60714 7.5601 1.81112 7.68793 2.062C7.78013 2.24295 7.80951 2.42277 7.82196 2.57517C7.8333 2.71394 7.83328 2.87781 7.83326 3.04607V5.62191C7.83328 5.79017 7.8333 5.95403 7.82196 6.09281C7.80951 6.2452 7.78013 6.42503 7.68793 6.60598C7.5601 6.85686 7.35613 7.06083 7.10524 7.18866C6.92429 7.28086 6.74447 7.31024 6.59208 7.32269C6.4533 7.33403 6.28944 7.33401 6.12118 7.33399H3.54534C3.37708 7.33401 3.21321 7.33403 3.07444 7.32269C2.92204 7.31024 2.74222 7.28086 2.56127 7.18866C2.31039 7.06083 2.10641 6.85686 1.97858 6.60598C1.88638 6.42503 1.857 6.2452 1.84455 6.09281C1.83321 5.95404 1.83323 5.79018 1.83325 5.62193L1.83325 3.04606C1.83323 2.8778 1.83321 2.71394 1.84455 2.57517C1.857 2.42277 1.88638 2.24295 1.97858 2.062C2.10641 1.81112 2.31039 1.60714 2.56127 1.47931C2.74222 1.38711 2.92204 1.35774 3.07444 1.34528C3.21321 1.33395 3.37707 1.33397 3.54532 1.33399ZM4.16659 4.33398C4.16659 3.96579 4.46506 3.66732 4.83325 3.66732H4.83992C5.20811 3.66732 5.50659 3.96579 5.50659 4.33398C5.50659 4.70217 5.20811 5.00065 4.83992 5.00065H4.83325C4.46506 5.00065 4.16659 4.70217 4.16659 4.33398Z"
        fill="#2058BB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.54532 8.66732C3.37709 8.6673 3.21319 8.66728 3.07444 8.67862C2.92204 8.69107 2.74222 8.72045 2.56127 8.81265C2.31039 8.94048 2.10641 9.14445 1.97858 9.39533C1.88638 9.57628 1.857 9.75611 1.84455 9.9085C1.83321 10.0473 1.83323 10.2111 1.83325 10.3794L1.83325 12.9553C1.83323 13.1235 1.83321 13.2874 1.84455 13.4261C1.857 13.5785 1.88638 13.7584 1.97858 13.9393C2.10641 14.1902 2.31039 14.3942 2.56127 14.522C2.74222 14.6142 2.92204 14.6436 3.07444 14.656C3.21319 14.6674 3.37703 14.6673 3.54526 14.6673H6.12118C6.28941 14.6673 6.45332 14.6674 6.59208 14.656C6.74447 14.6436 6.92429 14.6142 7.10524 14.522C7.35613 14.3942 7.5601 14.1902 7.68793 13.9393C7.78013 13.7584 7.80951 13.5785 7.82196 13.4261C7.8333 13.2874 7.83328 13.1235 7.83326 12.9552V10.3794C7.83328 10.2111 7.8333 10.0473 7.82196 9.9085C7.80951 9.75611 7.78013 9.57628 7.68793 9.39533C7.5601 9.14445 7.35613 8.94048 7.10524 8.81265C6.92429 8.72045 6.74447 8.69107 6.59208 8.67862C6.45332 8.66728 6.28948 8.6673 6.12124 8.66732H3.54532ZM4.83325 11.0007C4.46506 11.0007 4.16659 11.2991 4.16659 11.6673C4.16659 12.0355 4.46506 12.334 4.83325 12.334H4.83992C5.20811 12.334 5.50659 12.0355 5.50659 11.6673C5.50659 11.2991 5.20811 11.0007 4.83992 11.0007H4.83325Z"
        fill="#2058BB"
      />
    </svg>
  );
}
