import { atom } from 'recoil'

export const frontImageUploadedAtom = atom<File | string>({
  key: 'idUpload:frontImageUploadedAtom',
  default: '',
})

export const backImageUploadedAtom = atom<File | string>({
  key: 'idUpload:backImageUploadedAtom',
  default: '',
})

export const customCameraAllowedAtom = atom<boolean>({
  key: 'idUpload:customCameraAllowedAtom',
  default: false,
})

export const customCameraAndMicAllowedAtom = atom<boolean>({
  key: 'idUpload:customCameraAndMicAllowedAtom',
  default: false,
})

export default {
  'idUpload:customCameraAllowedAtom': customCameraAllowedAtom,
  'idUpload:customCameraAndMicAllowedAtom': customCameraAndMicAllowedAtom,
  'idUpload:frontImageUploadedAtom': frontImageUploadedAtom,
  'idUpload:backImageUploadedAtom': backImageUploadedAtom,
}
