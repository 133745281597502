/* eslint-disable react/function-component-definition */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
  isTimeOut?: boolean;
}

export default function ClockIcon({ ...props }: Iprops) {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 11C0.5 4.92487 5.42487 0 11.5 0C17.5751 0 22.5 4.92487 22.5 11C22.5 17.0751 17.5751 22 11.5 22C5.42487 22 0.5 17.0751 0.5 11ZM12.5 5C12.5 4.44772 12.0523 4 11.5 4C10.9477 4 10.5 4.44772 10.5 5V11C10.5 11.3788 10.714 11.725 11.0528 11.8944L15.0528 13.8944C15.5468 14.1414 16.1474 13.9412 16.3944 13.4472C16.6414 12.9532 16.4412 12.3526 15.9472 12.1056L12.5 10.382V5Z"
        fill={props.isTimeOut ? "#F32828" : "#3B8DD4"}
      />
    </svg>
  );
}
