/* eslint-disable react/function-component-definition */

export default function HeadphoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66663 10.0001C1.66663 5.39771 5.39759 1.66675 9.99996 1.66675C14.6023 1.66675 18.3333 5.39771 18.3333 10.0001V15.4167C18.3333 17.0276 17.0275 18.3334 15.4166 18.3334C13.8058 18.3334 12.5 17.0276 12.5 15.4167V13.7501C12.5 12.1393 13.8058 10.8334 15.4166 10.8334C15.864 10.8334 16.2878 10.9341 16.6666 11.1141V10.0001C16.6666 6.31818 13.6819 3.33341 9.99996 3.33341C6.31806 3.33341 3.33329 6.31818 3.33329 10.0001V11.1141C3.71215 10.9341 4.13596 10.8334 4.58329 10.8334C6.19412 10.8334 7.49996 12.1393 7.49996 13.7501V15.4167C7.49996 17.0276 6.19412 18.3334 4.58329 18.3334C2.97246 18.3334 1.66663 17.0276 1.66663 15.4167V10.0001Z"
        fill="black"
        fillOpacity="0.36"
      />
    </svg>
  );
}
