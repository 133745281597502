import { atom } from "recoil";

export const emailToSendDocsAtom = atom<string>({
  key: "disbursement:emailToSendDocsAtom",
  default: ""
});

export interface BranchList {
  code: string
  serialNumber: string
  name: string
  address: string
}

export const branchListAtom = atom<BranchList[] | null>({
  key: "disbursement:branchListAtom",
  default: null
});

interface IcityList {
  city: string
  districts: {
    district: string
  }[]
  branchId: string
}

export const cityListAtom = atom<IcityList[] | null>({
  key: "disbursement:cityListAtom",
  default: null
});

export const successfullDisbursementMethodAtom = atom<string>({
  key: "disbursement:successfullDisbursementMethodAtom",
  default: ""
});

export default {
  "disbursement:emailToSendDocsAtom": emailToSendDocsAtom,
  "disbursement:branchListAtom": branchListAtom,
  "disbursement:cityListAtom": cityListAtom,
  "disbursement:successfullDisbursementMethodAtom": successfullDisbursementMethodAtom
};
