import { atom } from "recoil";

export const returnToFunnelAtom = atom({
  key: "returnToFunnelState",
  default: {
    showReturnToFunnel: false,
    videoUrl: ""
  }
});

export default {
  "cashLoan:returnToFunnelAtom": returnToFunnelAtom
};
