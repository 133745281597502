import React, { createContext, ReactNode } from 'react'

interface ISelectInputProps {
  children: ReactNode | ReactNode[]
  selectedOptionValue: string
}

const defaultSelectInputState = {
  selectedOptionValue: '',
}

export const SelectInputStateContext = createContext(defaultSelectInputState)

export const CtxProvider = ({ children, selectedOptionValue }: ISelectInputProps) => {
  return (
    <>
      <SelectInputStateContext.Provider
        value={{
          selectedOptionValue,
        }}
      >
        {children}
      </SelectInputStateContext.Provider>
    </>
  )
}
