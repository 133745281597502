/* eslint-disable react/function-component-definition */
export default function CashbackIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <path
        d="M10.992 9.27533C10.992 8.15482 11.2907 7.29288 11.9733 6.73262C12.6133 6.17236 13.5947 5.87069 14.8747 5.87069C16.112 5.87069 17.2213 6.17236 18.2027 6.73262L19.6533 2.89701C19.0133 2.50914 18.2453 2.25056 17.3493 2.03508C16.4533 1.81959 15.5147 1.7334 14.4907 1.7334C11.8027 1.7334 9.712 2.37985 8.21867 3.71585C6.72533 5.00875 6 6.90501 6 9.31843V19.834H10.9493V13.7574H17.7333V9.87869H10.992V9.27533Z"
        fill="#3B8DD4"
      />
      <path
        d="M16.9653 30.0911C22.512 26.2124 25.84 20.2219 27.3333 13.8436L23.024 9.49084C21.9147 17.119 17.776 23.4973 13.8933 25.7383C13.4667 25.4797 12.2293 24.6609 11.248 23.5835C10.9576 23.7113 10.6685 23.8418 10.3787 23.9726C9.00774 24.5914 7.62017 25.2177 6 25.609C7.87733 27.8501 9.02933 28.7982 10.6933 30.0049C12.528 31.384 15.088 31.4271 16.9653 30.0911Z"
        fill="#3B8DD4"
      />
    </svg>
  );
}
