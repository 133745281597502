import COLORS from './COLORS'

const createColorCssVariables = () => {
  return `
  :root {
    ${COLORS.map(({ main, shades, tints }) => {
    return `
        --${main.label}: ${main.value};
        ${shades.map(({ label, value }) => {
    return `
          --${main.label}-${label}: ${value};
        `
  })}
        ${tints.map(({ label, value }) => {
    return `
          --${main.label}-${label}: ${value};
        `
  })}
    `
  })}
  }
`
}

export default createColorCssVariables
