import { atom } from "recoil";

import { LOCAL_STORAGE_LANG_KEY } from "layout/reusables/SelectLocale/data";

const languageAtom = atom({
  key: "languageAtom",
  default: localStorage.getItem(LOCAL_STORAGE_LANG_KEY) || "az"
});

export default languageAtom;
