/* eslint-disable react/function-component-definition */
export default function UmbrellaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <path
        d="M16.3333 1.7334C9.02743 1.7334 2.97141 7.07395 1.85186 14.0642C1.84561 14.1032 1.83897 14.1433 1.83214 14.1846C1.75012 14.68 1.64225 15.3315 1.89629 16.0063C2.10493 16.5605 2.5553 17.0887 3.06952 17.3824C3.44045 17.5942 3.80919 17.6719 4.14173 17.7049C4.4298 17.7335 4.76225 17.7335 5.08677 17.7334L15 17.7334V26.2667C15 28.8679 17.0406 31.0667 19.6667 31.0667C22.2928 31.0667 24.3334 28.8679 24.3334 26.2667C24.3334 25.5304 23.7364 24.9334 23 24.9334C22.2637 24.9334 21.6667 25.5304 21.6667 26.2667C21.6667 27.4947 20.7225 28.4001 19.6667 28.4001C18.6109 28.4001 17.6667 27.4947 17.6667 26.2667V17.7334L27.5799 17.7334C27.9044 17.7335 28.2369 17.7335 28.5249 17.7049C28.8575 17.6719 29.2262 17.5942 29.5971 17.3824C30.1114 17.0887 30.5617 16.5605 30.7704 16.0063C31.0244 15.3315 30.9165 14.6799 30.8345 14.1845C30.8277 14.1433 30.8211 14.1032 30.8148 14.0642C29.6953 7.07395 23.6392 1.7334 16.3333 1.7334Z"
        fill="#3B8DD4"
      />
    </svg>
  );
}
