interface Iprops {
  color?: string
  scale?: string
  className?: string
}

export default function CheckIcon({
  color = 'currentColor',
  className,
  scale = '1',
  ...props
}: Iprops) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      width={`${16 * +scale}`}
      height={`${16 * +scale}`}
      fill={color}
      className={className || ''}
      viewBox={`0 0 ${16 * +scale} ${16 * +scale}`}
      {...props}
    >
      <path
        style={{ transform: `scale(${scale})` }}
        d="M10.97 4.97a.75.75 0 011.07 1.05l-3.99 4.99a.75.75 0 01-1.08.02L4.324 8.384a.75.75 0 111.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 01.02-.022z"
      />
    </svg>
  )
}
