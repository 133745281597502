/* eslint-disable object-curly-newline */
import { Button, ButtonProps } from "@chakra-ui/react";

type BtnType = "primary" | "secondary" | "white" | "text" | "sme_primary" | "sme_white";

interface Props extends ButtonProps {
  label: string;
  btnType: BtnType;
}

const getStyleByType = (btnType: BtnType) => {
  const styleMap = {
    primary: {
      color: "white",
      bgColor: "#2058BB",
      _hover: {
        bgColor: "#1d4ea5"
      }
    },
    secondary: {
      bgColor: "#2058BB1F",
      color: "#2058BB",
      _hover: {
        bgColor: "#2058bb29"
      }
    },
    white: {
      bgColor: "white",
      color: "#2058BB",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      _hover: {
        bgColor: "#f9f9f9"
      }
    },
    text: {
      bgColor: "transparent!important",
      color: "#2058BB"
    },
    sme_primary: {
      bgColor: "sme.primary",
      color: "white",
      height: "48px",
      _hover: {
        bgColor: "#2C1A8F"
      }
    },
    sme_white: {
      border: "1px solid",
      borderColor: "onSurface.quaternary",
      bgColor: "white",
      color: "onSurface.primary",
      height: "48px",
      _hover: {
        bgColor: "#2C1A8F",
        color: "white",
        border: 0
      }
    }
  };

  return styleMap[btnType as BtnType];
};

export const ChakraButton = ({
  label,
  btnType = "primary",
  ...rest
}: Props) => (
  <Button
    borderRadius="8px"
    fontWeight={500}
    display="flex"
    w="100%"
    h={{ base: "40px", md: "48px" }}
    fontSize={{ base: "16px", md: "18px" }}
    _disabled={{
      bg: "#E4E4E4!important",
      color: "rgba(0, 0, 0, 0.5)!important"
    }}
    {...getStyleByType(btnType)}
    {...rest}
  >
    {label}
  </Button>
);
