/* eslint-disable react/function-component-definition */
export default function PersonIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2242 24.8049C7.85482 21.3234 11.9985 19.0674 16.6667 19.0674C21.3348 19.0674 25.4785 21.3234 28.1091 24.8049C28.121 24.8206 28.133 24.8364 28.145 24.8522C28.3712 25.1514 28.6137 25.4721 28.787 25.7815C28.9968 26.156 29.1523 26.5892 29.1426 27.1161C29.1349 27.5397 29.0065 27.9362 28.8507 28.2492C28.6949 28.5623 28.4559 28.9037 28.1226 29.1652C27.6782 29.514 27.1943 29.6375 26.7645 29.689C26.3874 29.7342 25.9404 29.7342 25.4911 29.7341C25.4709 29.7341 25.4506 29.7341 25.4304 29.7341H7.90298C7.88273 29.7341 7.86248 29.7341 7.84224 29.7341C7.3929 29.7342 6.94599 29.7342 6.56886 29.689C6.13901 29.6375 5.65514 29.514 5.2107 29.1652C4.87744 28.9037 4.63847 28.5623 4.48264 28.2492C4.3268 27.9362 4.19848 27.5397 4.19073 27.1161C4.1811 26.5892 4.33657 26.156 4.54635 25.7815C4.71967 25.4721 4.96219 25.1514 5.18838 24.8522C5.20037 24.8364 5.21231 24.8206 5.2242 24.8049Z"
        fill="#3B8DD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33334 10.4007C9.33334 6.35063 12.6166 3.06738 16.6667 3.06738C20.7168 3.06738 24 6.35063 24 10.4007C24 14.4508 20.7168 17.734 16.6667 17.734C12.6166 17.734 9.33334 14.4508 9.33334 10.4007Z"
        fill="#3B8DD4"
      />
    </svg>
  );
}
