export default function ApplicationIcon() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9022 1.66699H15.0978C13.7562 1.66697 12.6489 1.66696 11.747 1.74065C10.8101 1.81719 9.94896 1.98147 9.14007 2.39362C7.88565 3.03277 6.86578 4.05264 6.22663 5.30706C5.81447 6.11595 5.6502 6.97712 5.57366 7.91395C5.49996 8.81593 5.49998 9.92321 5.5 11.2648V28.7359C5.49998 30.0774 5.49996 31.1847 5.57366 32.0867C5.6502 33.0235 5.81447 33.8847 6.22663 34.6936C6.86578 35.948 7.88565 36.9679 9.14007 37.607C9.94896 38.0192 10.8101 38.1835 11.747 38.26C12.6489 38.3337 13.7562 38.3337 15.0978 38.3337H22.1912C22.2403 38.3337 22.2649 38.3337 22.2793 38.3332C22.9938 38.3084 23.3474 37.4546 22.8598 36.9319C22.8499 36.9213 22.8325 36.9039 22.7978 36.8692C20.8452 34.9166 20.8452 31.7507 22.7978 29.7981C24.6826 27.9134 27.6977 27.8479 29.661 29.6016L33.6311 25.6315C33.9417 25.3209 34.283 25.0597 34.6452 24.8479C35.0155 24.6314 35.2006 24.5232 35.2881 24.4253C35.3797 24.3227 35.4173 24.2571 35.4596 24.1262C35.5 24.0013 35.5 23.8369 35.5 23.5081V11.2648C35.5 9.92319 35.5 8.81594 35.4263 7.91395C35.3498 6.97712 35.1855 6.11595 34.7734 5.30706C34.1342 4.05264 33.1144 3.03277 31.8599 2.39362C31.051 1.98147 30.1899 1.81719 29.253 1.74065C28.3511 1.66696 27.2438 1.66697 25.9022 1.66699ZM13.8333 15.0003C12.9129 15.0003 12.1667 15.7465 12.1667 16.667C12.1667 17.5875 12.9129 18.3337 13.8333 18.3337H23.8333C24.7538 18.3337 25.5 17.5875 25.5 16.667C25.5 15.7465 24.7538 15.0003 23.8333 15.0003H13.8333ZM12.1667 23.3337C12.1667 22.4132 12.9129 21.667 13.8333 21.667H17.1667C18.0871 21.667 18.8333 22.4132 18.8333 23.3337C18.8333 24.2541 18.0871 25.0003 17.1667 25.0003H13.8333C12.9129 25.0003 12.1667 24.2541 12.1667 23.3337ZM13.8333 8.33366C12.9129 8.33366 12.1667 9.07985 12.1667 10.0003C12.1667 10.9208 12.9129 11.667 13.8333 11.667H27.1667C28.0871 11.667 28.8333 10.9208 28.8333 10.0003C28.8333 9.07985 28.0871 8.33366 27.1667 8.33366H13.8333Z"
        fill="#3B8DD4"
      />
      <path
        d="M38.3452 30.3455C38.9961 29.6946 38.9961 28.6394 38.3452 27.9885C37.6943 27.3376 36.639 27.3376 35.9882 27.9885L29.6667 34.31L27.5118 32.1552C26.861 31.5043 25.8057 31.5043 25.1548 32.1552C24.5039 32.806 24.5039 33.8613 25.1548 34.5122L28.4882 37.8455C29.139 38.4964 30.1943 38.4964 30.8452 37.8455L38.3452 30.3455Z"
        fill="#3B8DD4"
      />
    </svg>
  );
}
