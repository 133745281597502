/* eslint-disable react/function-component-definition */

export default function StopwatchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6666 1.66663C15.7462 1.66663 15 2.41282 15 3.33329C15 4.25377 15.7462 4.99996 16.6666 4.99996H18.3333V6.7533C10.3722 7.58613 4.16663 14.3183 4.16663 22.5C4.16663 31.2445 11.2555 38.3333 20 38.3333C28.7445 38.3333 35.8333 31.2445 35.8333 22.5C35.8333 14.3183 29.6277 7.58613 21.6666 6.7533V4.99996H23.3333C24.2538 4.99996 25 4.25377 25 3.33329C25 2.41282 24.2538 1.66663 23.3333 1.66663H16.6666ZM21.6666 15.8333C21.6666 14.9128 20.9204 14.1666 20 14.1666C19.0795 14.1666 18.3333 14.9128 18.3333 15.8333V22.5C18.3333 23.0854 18.6405 23.6279 19.1425 23.9291L23.3091 26.4291C24.0984 26.9027 25.1222 26.6468 25.5958 25.8575C26.0694 25.0681 25.8134 24.0444 25.0241 23.5708L21.6666 21.5563V15.8333Z"
        fill="#2058BB"
      />
      <path
        d="M30.2033 5.64111C30.8542 4.99024 31.9094 4.99024 32.5603 5.64111L35.0603 8.14111C35.7112 8.79198 35.7112 9.84726 35.0603 10.4981C34.4094 11.149 33.3542 11.149 32.7033 10.4981L30.2033 7.99813C29.5524 7.34726 29.5524 6.29198 30.2033 5.64111Z"
        fill="#2058BB"
      />
      <path
        d="M9.79663 5.64111C10.4475 6.29198 10.4475 7.34726 9.79663 7.99813L7.29663 10.4981C6.64576 11.149 5.59049 11.149 4.93961 10.4981C4.28874 9.84726 4.28874 8.79198 4.93961 8.14111L7.43961 5.64111C8.09049 4.99024 9.14576 4.99024 9.79663 5.64111Z"
        fill="#2058BB"
      />
    </svg>
  );
}
