/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function CopyIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8265 7H13.5036C14.0307 6.99998 14.4855 6.99997 14.8601 7.03057C15.2554 7.06287 15.6486 7.13419 16.027 7.32698C16.5915 7.6146 17.0504 8.07354 17.3381 8.63803C17.5309 9.01641 17.6022 9.40963 17.6345 9.80497C17.6651 10.1795 17.6651 10.6343 17.665 11.1614V18.8385C17.6651 19.3657 17.6651 19.8205 17.6345 20.195C17.6022 20.5904 17.5309 20.9836 17.3381 21.362C17.0504 21.9265 16.5915 22.3854 16.027 22.673C15.6486 22.8658 15.2554 22.9371 14.8601 22.9694C14.4855 23 14.0308 23 13.5037 23H5.82652C5.29941 23 4.84456 23 4.47001 22.9694C4.07467 22.9371 3.68145 22.8658 3.30307 22.673C2.73858 22.3854 2.27964 21.9265 1.99202 21.362C1.79922 20.9836 1.72791 20.5904 1.69561 20.195C1.665 19.8205 1.66502 19.3657 1.66504 18.8385V11.1615C1.66502 10.6343 1.665 10.1796 1.69561 9.80497C1.72791 9.40963 1.79922 9.01641 1.99202 8.63803C2.27964 8.07354 2.73858 7.6146 3.30307 7.32698C3.68145 7.13419 4.07467 7.06287 4.47001 7.03057C4.84457 6.99997 5.29936 6.99998 5.8265 7Z"
        fill="black"
        fillOpacity="0.64"
      />
      <path
        d="M19.5036 1H11.8265C11.2994 0.999984 10.8446 0.999969 10.47 1.03057C10.0747 1.06287 9.68145 1.13419 9.30307 1.32698C8.73859 1.6146 8.27964 2.07354 7.99202 2.63803C7.79923 3.01641 7.72791 3.40963 7.69561 3.80497C7.66818 4.14076 7.66535 4.54098 7.66507 5.00003L13.5459 5.00001C14.0345 4.9999 14.5644 4.99977 15.0229 5.03724C15.542 5.07964 16.2277 5.1846 16.935 5.54499C17.8758 6.02436 18.6407 6.78926 19.1201 7.73007C19.4805 8.43739 19.5854 9.12311 19.6278 9.64213C19.6653 10.1007 19.6652 10.6305 19.6651 11.1192L19.665 17C20.1241 16.9997 20.5243 16.9969 20.8601 16.9694C21.2554 16.9371 21.6486 16.8658 22.027 16.673C22.5915 16.3854 23.0504 15.9265 23.3381 15.362C23.5309 14.9836 23.6022 14.5904 23.6345 14.195C23.6651 13.8205 23.6651 13.3658 23.665 12.8386V5.16148C23.6651 4.63437 23.6651 4.17952 23.6345 3.80497C23.6022 3.40963 23.5309 3.01641 23.3381 2.63803C23.0504 2.07354 22.5915 1.6146 22.027 1.32698C21.6486 1.13419 21.2554 1.06287 20.8601 1.03057C20.4855 0.999969 20.0307 0.999984 19.5036 1Z"
        fill="black"
        fillOpacity="0.64"
      />
    </svg>
  );
}
