/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function CalendarIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5834 1.33331C22.3198 1.33331 22.9167 1.93027 22.9167 2.66665V4.00215C23.5507 4.00651 24.1048 4.0196 24.5858 4.05891C25.3353 4.12014 26.0243 4.25156 26.6714 4.58128C27.6749 5.0926 28.4908 5.9085 29.0021 6.91203C29.3318 7.55915 29.4633 8.24808 29.5245 8.99755C29.5834 9.71913 29.5834 10.6049 29.5834 11.6782V22.9884C29.5834 24.0617 29.5834 24.9475 29.5245 25.6691C29.4633 26.4185 29.3318 27.1075 29.0021 27.7546C28.4908 28.7581 27.6749 29.574 26.6714 30.0853C26.0243 30.4151 25.3353 30.5465 24.5858 30.6077C23.8643 30.6667 22.9785 30.6667 21.9052 30.6666H10.595C9.5217 30.6667 8.6359 30.6667 7.91431 30.6077C7.16485 30.5465 6.47591 30.4151 5.8288 30.0853C4.82527 29.574 4.00937 28.7581 3.49805 27.7546C3.16833 27.1075 3.03691 26.4185 2.97567 25.6691C2.91672 24.9475 2.91673 24.0617 2.91675 22.9884V11.6782C2.91673 10.605 2.91672 9.71913 2.97567 8.99755C3.03691 8.24808 3.16833 7.55915 3.49805 6.91203C4.00937 5.9085 4.82527 5.0926 5.8288 4.58128C6.47591 4.25156 7.16485 4.12014 7.91431 4.05891C8.39539 4.0196 8.94946 4.00651 9.58342 4.00215V2.66665C9.58342 1.93027 10.1804 1.33331 10.9167 1.33331C11.6531 1.33331 12.2501 1.93027 12.2501 2.66665V3.99998H20.2501V2.66665C20.2501 1.93027 20.847 1.33331 21.5834 1.33331ZM9.58342 6.669V7.99998C9.58342 8.73636 10.1804 9.33331 10.9167 9.33331C11.6531 9.33331 12.2501 8.73636 12.2501 7.99998V6.66665H20.2501V7.99998C20.2501 8.73636 20.847 9.33331 21.5834 9.33331C22.3198 9.33331 22.9167 8.73636 22.9167 7.99998V6.669C23.5108 6.67317 23.9772 6.68473 24.3687 6.71672C24.9533 6.76448 25.2522 6.85104 25.4607 6.9573C25.9625 7.21296 26.3704 7.62091 26.6261 8.12267C26.7323 8.33121 26.8189 8.63014 26.8667 9.2147C26.9157 9.81482 26.9167 10.5912 26.9167 11.7333V12H5.58342V11.7333C5.58342 10.5912 5.58445 9.81482 5.63349 9.2147C5.68125 8.63014 5.76781 8.33121 5.87407 8.12267C6.12973 7.62091 6.53768 7.21296 7.03944 6.9573C7.24797 6.85104 7.54691 6.76448 8.13147 6.71672C8.52292 6.68473 8.98938 6.67317 9.58342 6.669Z"
        fill="#3B8DD4"
      />
    </svg>
  );
}
