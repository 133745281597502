/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function ShareIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.52827 1.52851C7.78862 1.26816 8.21073 1.26816 8.47108 1.52851L11.1377 4.19518C11.3981 4.45553 11.3981 4.87764 11.1377 5.13799C10.8774 5.39834 10.4553 5.39834 10.1949 5.13799L8.66634 3.60939L8.66634 9.99992C8.66634 10.3681 8.36786 10.6666 7.99967 10.6666C7.63148 10.6666 7.33301 10.3681 7.33301 9.99992L7.33301 3.60939L5.80441 5.13799C5.54406 5.39834 5.12195 5.39834 4.8616 5.13799C4.60125 4.87764 4.60125 4.45553 4.8616 4.19518L7.52827 1.52851Z"
        fill="#2058BB"
      />
      <path
        d="M1.99968 7.33325C2.36787 7.33325 2.66634 7.63173 2.66634 7.99992V10.7999C2.66634 11.371 2.66686 11.7592 2.69138 12.0592C2.71526 12.3515 2.75854 12.501 2.81167 12.6052C2.9395 12.8561 3.14347 13.0601 3.39435 13.1879C3.49862 13.2411 3.64809 13.2843 3.94037 13.3082C4.24043 13.3327 4.62862 13.3333 5.19968 13.3333H10.7997C11.3707 13.3333 11.7589 13.3327 12.059 13.3082C12.3513 13.2843 12.5007 13.2411 12.605 13.1879C12.8559 13.0601 13.0599 12.8561 13.1877 12.6052C13.2408 12.501 13.2841 12.3515 13.308 12.0592C13.3325 11.7592 13.333 11.371 13.333 10.7999V7.99992C13.333 7.63173 13.6315 7.33325 13.9997 7.33325C14.3679 7.33325 14.6663 7.63173 14.6663 7.99992V10.8275C14.6663 11.3641 14.6664 11.807 14.6369 12.1678C14.6063 12.5425 14.5406 12.887 14.3757 13.2106C14.12 13.7123 13.7121 14.1203 13.2103 14.3759C12.8868 14.5408 12.5423 14.6065 12.1676 14.6371C11.8068 14.6666 11.3638 14.6666 10.8272 14.6666H5.17216C4.6355 14.6666 4.19259 14.6666 3.83179 14.6371C3.45706 14.6065 3.11259 14.5408 2.78903 14.3759C2.28727 14.1203 1.87932 13.7123 1.62366 13.2106C1.4588 12.887 1.39309 12.5425 1.36247 12.1678C1.33299 11.807 1.333 11.3641 1.33301 10.8275V7.99992C1.33301 7.63173 1.63149 7.33325 1.99968 7.33325Z"
        fill="#2058BB"
      />
    </svg>
  );
}
