/* eslint-disable react/function-component-definition */

export default function PaperMoneyIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={color}
    >
      <path
        d="M12.5 10.5C11.6716 10.5 11 11.1716 11 12C11 12.8284 11.6716 13.5 12.5 13.5C13.3284 13.5 14 12.8284 14 12C14 11.1716 13.3284 10.5 12.5 10.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 3C15.337 3 13.4616 3.54246 11.8309 4.04422L11.5264 4.13813C9.98584 4.61372 8.73458 5 7.50001 5C6.61417 5 5.8228 4.90583 5.15652 4.77781L5.12507 4.77177C4.63697 4.67798 4.22704 4.5992 3.90848 4.55709C3.629 4.52015 3.21485 4.47624 2.83612 4.60951C2.63763 4.67936 2.42293 4.77899 2.21995 4.94669C2.01696 5.11439 1.87861 5.30643 1.77256 5.48818C1.57777 5.82202 1.53722 6.2004 1.51924 6.471C1.49998 6.76088 1.49999 7.12959 1.50001 7.55506L1.5 18.3507C1.49994 18.5478 1.49987 18.7819 1.61895 19.1116C1.67809 19.2754 1.77548 19.4334 1.83171 19.5197C1.88794 19.606 1.99326 19.7589 2.11921 19.8791C2.20064 19.9568 2.2935 20.039 2.40469 20.112C2.51374 20.1835 2.61306 20.227 2.65641 20.246L2.6643 20.2495C3.48405 20.6111 4.95447 21 7.50001 21C9.66298 21 11.5384 20.4575 13.1691 19.9558L13.4736 19.8619C15.0142 19.3863 16.2654 19 17.5 19C18.3858 19 19.1772 19.0942 19.8435 19.2222L19.875 19.2282C20.3631 19.322 20.773 19.4008 21.0915 19.4429C21.371 19.4798 21.7852 19.5238 22.1639 19.3905C22.3624 19.3206 22.5771 19.221 22.7801 19.0533C22.9831 18.8856 23.1214 18.6936 23.2274 18.5118C23.4222 18.178 23.4628 17.7996 23.4808 17.529C23.5 17.2391 23.5 16.8704 23.5 16.445L23.5 5.64931C23.5001 5.45216 23.5001 5.21806 23.3811 4.88838C23.3219 4.72464 23.2245 4.56656 23.1683 4.4803C23.1121 4.39405 23.0068 4.24114 22.8808 4.12094C22.7994 4.04322 22.7065 3.96101 22.5953 3.88804C22.4863 3.81648 22.3869 3.77295 22.3436 3.75396L22.3357 3.7505C21.516 3.38891 20.0455 3 17.5 3ZM19.5 9C19.5 8.44772 19.0523 8 18.5 8C17.9477 8 17.5 8.44772 17.5 9V13C17.5 13.5523 17.9477 14 18.5 14C19.0523 14 19.5 13.5523 19.5 13V9ZM9 12C9 10.067 10.567 8.5 12.5 8.5C14.433 8.5 16 10.067 16 12C16 13.933 14.433 15.5 12.5 15.5C10.567 15.5 9 13.933 9 12ZM6.5 10C7.05228 10 7.5 10.4477 7.5 11V15C7.5 15.5523 7.05228 16 6.5 16C5.94772 16 5.5 15.5523 5.5 15V11C5.5 10.4477 5.94772 10 6.5 10Z"
        fill={color}
      />
    </svg>
  );
}
