/* eslint-disable react/function-component-definition */

export default function CreditCardIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={color}
    >
      <path
        d="M19.3385 4L5.66146 4C5.13435 3.99998 4.67953 3.99997 4.30498 4.03057C3.90963 4.06287 3.51641 4.13419 3.13803 4.32698C2.57355 4.6146 2.1146 5.07355 1.82698 5.63803C1.63419 6.01641 1.56287 6.40963 1.53057 6.80498C1.49997 7.17952 1.49999 7.63428 1.5 8.16138C1.5 8.62452 1.87545 9 2.33859 9H22.6614C23.1246 9 23.5 8.62456 23.5 8.16144C23.5 7.6343 23.5 7.17954 23.4694 6.80498C23.4371 6.40963 23.3658 6.01641 23.173 5.63803C22.8854 5.07355 22.4265 4.61461 21.862 4.32699C21.4836 4.13419 21.0904 4.06288 20.695 4.03057C20.3205 3.99997 19.8657 3.99999 19.3385 4Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.04601 11.109C2.25992 11 2.53995 11 3.1 11H21.9C22.4601 11 22.7401 11 22.954 11.109C23.1422 11.2049 23.2951 11.3578 23.391 11.546C23.5 11.7599 23.5 12.04 23.5 12.6V15.8386C23.5 16.3657 23.5 16.8205 23.4694 17.195C23.4371 17.5904 23.3658 17.9836 23.173 18.362C22.8854 18.9265 22.4265 19.3854 21.862 19.673C21.4836 19.8658 21.0904 19.9371 20.695 19.9694C20.3205 20 19.8657 20 19.3386 20L5.66129 20C5.13424 20 4.67949 20 4.30498 19.9694C3.90963 19.9371 3.51641 19.8658 3.13803 19.673C2.57355 19.3854 2.1146 18.9265 1.82698 18.362C1.63419 17.9836 1.56287 17.5904 1.53057 17.195C1.49997 16.8205 1.49999 16.3657 1.5 15.8386V12.6C1.5 12.0399 1.5 11.7599 1.60899 11.546C1.70487 11.3578 1.85785 11.2049 2.04601 11.109ZM6.5 14C5.94772 14 5.5 14.4477 5.5 15C5.5 15.5523 5.94772 16 6.5 16H11.5C12.0523 16 12.5 15.5523 12.5 15C12.5 14.4477 12.0523 14 11.5 14H6.5Z"
        fill={color}
      />
    </svg>
  );
}
