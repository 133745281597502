import { atom } from 'recoil'

export const otpErrorTotalAtom = atom<number>({
  key: 'otp:errorCount',
  default: 0,
})

export default {
  'otp:errorCount': otpErrorTotalAtom,
}
