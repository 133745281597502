import { PropsWithChildren } from "react";

import { Card, CardBody, CardProps } from "@chakra-ui/react";

export const ChakraCard = ({
  children,
  ...props
}: PropsWithChildren<CardProps>) => (
  <Card
    borderRadius="16px"
    px={{ base: "16px", sm: "32px", md: "64px" }}
    py="56px"
    maxWidth="840px"
    margin="0 auto"
    {...props}
  >
    <CardBody padding="0">{children}</CardBody>
  </Card>
);
