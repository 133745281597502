/* eslint-disable react/function-component-definition */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export const HelpCircle = ({ ...props }: Iprops) => (
  <svg
    width="65"
    height="64"
    viewBox="0 0 65 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.5 2.66675C16.2996 2.66675 3.16663 15.7997 3.16663 32.0001C3.16663 48.2004 16.2996 61.3334 32.5 61.3334C48.7003 61.3334 61.8333 48.2004 61.8333 32.0001C61.8333 15.7997 48.7003 2.66675 32.5 2.66675ZM29.5843 22.0567C30.6702 21.4185 31.9469 21.1852 33.1883 21.3982C34.4296 21.6111 35.5556 22.2565 36.3667 23.2201C37.1779 24.1836 37.6218 25.4032 37.6199 26.6627L37.6199 26.6666C37.6199 27.9177 36.6463 29.2219 34.8074 30.4478C33.9691 31.0067 33.1118 31.4373 32.4536 31.7298C32.1282 31.8744 31.8609 31.9812 31.681 32.0497C31.6012 32.0801 31.5211 32.1095 31.4405 32.1378C30.0451 32.6046 29.2914 34.1137 29.7568 35.5099C30.2225 36.9071 31.7327 37.6622 33.1299 37.1965L33.5797 37.0336C33.8374 36.9354 34.195 36.7922 34.6196 36.6035C35.4614 36.2294 36.6041 35.6599 37.7658 34.8854C39.9264 33.445 42.9521 30.75 42.9533 26.6691C42.9567 24.1506 42.0688 21.7121 40.4469 19.7854C38.8246 17.8583 36.5727 16.5675 34.0899 16.1416C31.6071 15.7157 29.0537 16.1823 26.882 17.4586C24.7103 18.735 23.0603 20.7388 22.2244 23.1151C21.7357 24.5044 22.4657 26.0268 23.855 26.5155C25.2443 27.0043 26.7668 26.2742 27.2555 24.8849C27.6735 23.6967 28.4984 22.6949 29.5843 22.0567ZM32.4999 42.6666C31.0272 42.6666 29.8333 43.8605 29.8333 45.3333C29.8333 46.8061 31.0272 48 32.4999 48H32.5266C33.9994 48 35.1933 46.8061 35.1933 45.3333C35.1933 43.8605 33.9994 42.6666 32.5266 42.6666H32.4999Z"
      fill="#FFA500"
    />
  </svg>
);

export default HelpCircle;
