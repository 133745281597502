/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  ChangeEventHandler,
  Children,
  cloneElement,
  isValidElement,
  KeyboardEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState
} from "react";

import {
  Box,
  Button,
  Flex,
  FormControlProps,
  FormLabel,
  Input,
  Text,
  UnorderedList
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import scrollIntoView from "scroll-into-view-if-needed";

import { CtxProvider } from "components/BaseComponents/selectInput/CtxProvider";
import { isOptionType } from "components/BaseComponents/selectInput/Option";
import DownIcon from "components/iconsIba/DownIcon";

interface ISelectProps extends FormControlProps {
  children: ReactNode[] | ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE?: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedOptionValue: (i: string) => void;
  renderLabel?: () => ReactNode | ReactNode[];
  selectedOptionValue: string;
  id: string;
  className?: string;
  label?: string;
  searchable?: boolean;
  disabled?: boolean;
  resetStyles?: boolean;
  downArrowClassName?: string;
  downArrowScale?: string;
}

const contentAnimation = {
  show: {
    X: 0,
    transition: {
      duration: 0.5
    }
  },
  hidden: {
    X: -100,
    transition: {
      duration: 0
    }
  }
};

export type optionNavigationDirection = "next" | "prev";

export const Select = ({
  children,
  setSelectedOptionValue,
  selectedOptionValue,
  id,
  renderLabel,
  label,
  searchable,
  disabled,
  resetStyles,
  downArrowClassName,
  downArrowScale,
  height = "56px",
  ...rest
}: ISelectProps) => {
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState("");
  const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const inputRef = useRef(document.createElement("input"));
  const dropdownRef = useRef(document.createElement("div"));
  const selectButtonRef = useRef(document.createElement("button"));
  const filterChildren = () => {
    if (!Array.isArray(children)) {
      return children;
    }

    return children.filter((child) => {
      if (isValidElement(child)) {
        const searchValue = child.props.searchValue || child.props.value;

        return searchValue.toLowerCase().startsWith(filterValue.toLowerCase());
      }

      return false;
    });
  };
  const childrenFiltered = filterValue.length ? filterChildren() : children;

  useEffect(() => {
    if (dropdownVisible) {
      inputRef.current.focus();
      setTimeout(() => {
        scrollIntoView(dropdownRef.current, {
          block: "nearest",
          scrollMode: "if-needed",
          behavior: "smooth",
          boundary: document.getElementById("abb-app-wrapper")
        });
      }, 0);
      if (selectedOptionValue) {
        if (!Array.isArray(childrenFiltered)) {
          return;
        }
        const indexNext = childrenFiltered?.findIndex((child) => {
          if (isValidElement(child)) {
            if ((child as any).props.value === selectedOptionValue) {
              return true;
            }
          }

          return false;
        });
        setFocusedItemIndex(indexNext);
      } else {
        setFocusedItemIndex(0);
      }
      inputRef.current.scrollTop = 0;
    } else {
      setFocusedItemIndex(-1);
      setFilterValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownVisible]);

  const handleFilterValueChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (!searchable) {
      return;
    }
    const { value } = event.target;
    setFilterValue(value.trimLeft());
    setFocusedItemIndex(0);
  };

  const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    const isSpacePressed = event.code === "Space";
    const isEnterPressed = event.code === "Enter";
    const isDownPressed = event.code === "ArrowDown";
    const isUpPressed = event.code === "ArrowUp";
    const isEscPressed = event.code === "Escape";
    const isTabPressed = event.code === "Tab";
    if (!Array.isArray(childrenFiltered)) {
      return;
    }
    if (isDownPressed) {
      event.preventDefault();
      const indexNext = focusedItemIndex + 1;
      if (indexNext < childrenFiltered.length) {
        setFocusedItemIndex(indexNext);
      }
    } else if (isUpPressed) {
      event.preventDefault();
      const indexNext = focusedItemIndex - 1;
      if (indexNext >= 0) {
        setFocusedItemIndex(indexNext);
      }
    } else if (
      isEnterPressed ||
      (isSpacePressed && selectedOptionValue === "")
    ) {
      if (childrenFiltered) {
        const focusedItem = childrenFiltered[focusedItemIndex];
        if (isValidElement(focusedItem)) {
          event.preventDefault();
          setSelectedOptionValue((focusedItem as any).props.value);
          selectButtonRef.current.focus();
          setDropdownVisible(false);
        }
      }
    } else if (isEscPressed) {
      setDropdownVisible(false);
      selectButtonRef.current.focus();
    } else if (isTabPressed) {
      setDropdownVisible(false);
    }
  };
  const handleLabelKeyDown: KeyboardEventHandler<HTMLButtonElement> = (
    event
  ) => {
    const isSpacePressed = event.code === "Space";
    const isEnterPressed = event.code === "Enter";
    const isDownPressed = event.code === "ArrowDown";
    const isUpPressed = event.code === "ArrowUp";
    if (isSpacePressed || isEnterPressed || isDownPressed || isUpPressed) {
      event.preventDefault();
      setDropdownVisible(true);
    }
  };

  return (
    <CtxProvider selectedOptionValue={selectedOptionValue}>
      <Box pos="relative" maxWidth="38rem" w="100%" margin="0 auto" {...rest}>
        {label && (
          <FormLabel mb="8px" fontSize="18px" fontWeight={500} color="#0D0D19">
            {label}
          </FormLabel>
        )}
        <Button
          ref={selectButtonRef}
          type="button"
          aria-haspopup="listbox"
          aria-labelledby={id}
          onFocus={() => { }}
          onKeyDown={handleLabelKeyDown}
          onClick={() => {
            setDropdownVisible((i) => !i);
          }}
          fontSize="18px"
          color="#0D0D19"
          fontWeight={400}
          borderRadius="8px"
          isDisabled={disabled}
          pos="relative"
          display="flex"
          outline="none"
          px="16px"
          alignItems="center"
          border="1px solid rgba(0, 0, 0, 0.12)"
          userSelect="none"
          {...(!resetStyles && {
            height,
            maxWidth: "380px",

            w: "100%",
            ...(dropdownVisible && {
              zIndex: 20
            }),
            ...(disabled
              ? {
                cursor: "default",
                bgColor: "gray"
              }
              : {
                cursor: "pointer",
                bgColor: "white!important"
              })
          })}
        >
          <Flex alignItems="center" w="100%" justifyContent="space-between">
            {renderLabel && renderLabel()}
            {!renderLabel && (
              <Box textAlign="left" flex="1 1 0%">
                {/* <Box
                  fontWeight={400}
                  letterSpacing="1.1"
                  color="rgba(153, 160, 172, 1)"
                >
                  {label}
                </Box> */}
                <Box>{selectedOptionValue || t("form.please_choose")}</Box>
              </Box>
            )}
            <Flex
              ml="16px"
              alignItems="center"
              transitionProperty="all"
              transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
              transitionDuration="150ms"
              // downArrowClassName
              transform={`rotate(${dropdownVisible ? "180" : "0"}deg)`}
              justifyContent="center"
            >
              <DownIcon scale={downArrowScale} />
            </Flex>
          </Flex>
        </Button>
        <AnimatePresence>
          {dropdownVisible && (
            <Flex
              as={motion.div}
              key="selectdropdown"
              ref={dropdownRef}
              variants={contentAnimation}
              initial="hidden"
              animate="show"
              exit="hidden"
              maxHeight="184px"
              bgColor="white"
              borderRadius="8px"
              zIndex={10}
              pos="absolute"
              flexDirection="column"
              left={0}
              right={0}
              py="8px"
              boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.16)"

            // {...(!resetStyles && {})}
            >
              <Flex
                px="16px"
                py="8px"
                pt={0}
                flexShrink={0}
                h="48px"
                bg="white"
                zIndex={20}
                borderColor="rgba(228, 228, 228,1)"
                hidden={!searchable}
                borderBottomWidth="1px"
              >
                <Input
                  type="text"
                  ref={inputRef}
                  value={filterValue}
                  onChange={handleFilterValueChange}
                  h="100%"
                  outline="none"
                  fontSize="16px"
                  borderWidth="1px"
                  borderColor="rgba(220, 220, 220, 1)"
                  onKeyDown={handleInputKeyDown}
                  readOnly={!searchable}
                />
              </Flex>

              <UnorderedList
                className="abb-custom-scrollbar-gray"
                role="listbox"
                overflow="auto"
                display="flex"
                flexDirection="column"
                aria-labelledby={id}
                id="select-parent"
                m={0}
              >
                {Children.map(childrenFiltered, (child, index) => {
                  if (
                    isValidElement(child) &&
                    isOptionType(child.props.__TYPE)
                  ) {
                    return cloneElement(child, {
                      index,
                      selectedOptionValue,
                      setSelectedOptionValue: (v: string) => {
                        setSelectedOptionValue(v);
                        setDropdownVisible(false);
                        selectButtonRef.current.focus();
                      },
                      lastItem:
                        Array.isArray(childrenFiltered) &&
                        index === childrenFiltered.length - 1,
                      focused: focusedItemIndex === index,
                      setFocusedOptionIndex: (i: number) => {
                        setFocusedItemIndex(i);
                      }
                    } as unknown as any);
                  }

                  return child;
                })}
                {Array.isArray(childrenFiltered) &&
                  childrenFiltered.length === 0 && (
                    <Text
                      color="rgba(153, 160, 172, 1)"
                      display="flex"
                      px="16px"
                      py="8px"
                      pb={0}
                      alignItems="center"
                      justifyContent="flex-start"
                      fontWeight={300}
                      h="48px"
                    >
                      No items match...
                    </Text>
                  )}
              </UnorderedList>
            </Flex>
          )}
        </AnimatePresence>
      </Box>
    </CtxProvider>
  );
};

const __TYPE = "Component/Radio/Select";
export const isSelectType = (key: string) => key === __TYPE;

Select.defaultProps = {
  __TYPE
};
