/* eslint-disable react/function-component-definition */
export default function PercentageIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3334 1.7334C8.23324 1.7334 1.66675 8.29989 1.66675 16.4001C1.66675 24.5002 8.23324 31.0667 16.3334 31.0667C24.4336 31.0667 31.0001 24.5002 31.0001 16.4001C31.0001 8.29989 24.4336 1.7334 16.3334 1.7334ZM22.6096 12.0095C23.1303 11.4888 23.1303 10.6446 22.6096 10.1239C22.0889 9.60322 21.2446 9.60322 20.7239 10.1239L10.0573 20.7906C9.53657 21.3113 9.53657 22.1555 10.0573 22.6762C10.578 23.1969 11.4222 23.1969 11.9429 22.6762L22.6096 12.0095ZM10.3334 12.4001C10.3334 11.2955 11.2288 10.4001 12.3334 10.4001C13.438 10.4001 14.3334 11.2955 14.3334 12.4001C14.3334 13.5046 13.438 14.4001 12.3334 14.4001C11.2288 14.4001 10.3334 13.5046 10.3334 12.4001ZM18.3334 20.4001C18.3334 19.2955 19.2288 18.4001 20.3334 18.4001C21.438 18.4001 22.3334 19.2955 22.3334 20.4001C22.3334 21.5046 21.438 22.4001 20.3334 22.4001C19.2288 22.4001 18.3334 21.5046 18.3334 20.4001Z"
        fill="#3B8DD4"
      />
    </svg>
  );
}
