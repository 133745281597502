import { atom } from 'recoil'

export const accessAndRefreshTokensAtom = atom<{ access: string; refresh: string }>({
  key: 'shared:accessAndRefreshTokensAtom',
  default: {
    access: '',
    refresh: '',
  },
})

export default {
  'shared:accessAndRefreshTokensAtom': accessAndRefreshTokensAtom,
}
