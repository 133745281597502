/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function QuestionIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5001 2.6665C16.2997 2.6665 3.16675 15.7995 3.16675 31.9998C3.16675 48.2002 16.2997 61.3332 32.5001 61.3332C48.7004 61.3332 61.8334 48.2002 61.8334 31.9998C61.8334 15.7995 48.7004 2.6665 32.5001 2.6665ZM29.5844 22.0564C30.6703 21.4183 31.947 21.185 33.1884 21.3979C34.4298 21.6108 35.5557 22.2562 36.3669 23.2198C37.178 24.1834 37.6219 25.4029 37.6201 26.6624L37.6201 26.6664C37.6201 27.9174 36.6465 29.2216 34.8075 30.4476C33.9692 31.0065 33.1119 31.437 32.4537 31.7296C32.1284 31.8742 31.861 31.9809 31.6812 32.0494C31.6014 32.0798 31.5212 32.1092 31.4407 32.1375C30.0453 32.6043 29.2915 34.1134 29.7569 35.5097C30.2226 36.9069 31.7328 37.6619 33.13 37.1962L33.5798 37.0334C33.8375 36.9352 34.1951 36.792 34.6198 36.6032C35.4615 36.2291 36.6042 35.6597 37.7659 34.8852C39.9266 33.4448 42.9522 30.7498 42.9534 26.6688C42.9568 24.1503 42.0689 21.7119 40.447 19.7851C38.8247 17.858 36.5728 16.5672 34.09 16.1413C31.6072 15.7155 29.0539 16.182 26.8821 17.4584C24.7104 18.7348 23.0604 20.7385 22.2245 23.1148C21.7358 24.5041 22.4658 26.0266 23.8551 26.5153C25.2445 27.004 26.7669 26.274 27.2556 24.8846C27.6736 23.6965 28.4986 22.6946 29.5844 22.0564ZM32.5001 42.6664C31.0273 42.6664 29.8334 43.8603 29.8334 45.3331C29.8334 46.8058 31.0273 47.9997 32.5001 47.9997H32.5267C33.9995 47.9997 35.1934 46.8058 35.1934 45.3331C35.1934 43.8603 33.9995 42.6664 32.5267 42.6664H32.5001Z"
        fill="#FFA500"
      />
    </svg>
  );
}
