/* eslint-disable react/function-component-definition */

export default function CashbackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M12.9066 11.094C12.9066 9.6934 13.28 8.61598 14.1333 7.91566C14.9333 7.21533 16.16 6.83824 17.76 6.83824C19.3066 6.83824 20.6933 7.21533 21.92 7.91566L23.7333 3.12114C22.9333 2.6363 21.9733 2.31308 20.8533 2.04372C19.7333 1.77437 18.56 1.66663 17.28 1.66663C13.92 1.66663 11.3066 2.47469 9.43996 4.14469C7.57329 5.76082 6.66663 8.13114 6.66663 11.1479V24.2924H12.8533V16.6966H21.3333V11.8482H12.9066V11.094Z"
        fill="#2058BB"
      />
      <path
        d="M20.3733 37.1137C27.3066 32.2654 31.4666 24.7773 33.3333 16.8044L27.9466 11.3634C26.56 20.8986 21.3866 28.8715 16.5333 31.6728C16 31.3496 14.4533 30.326 13.2266 28.9792C12.8637 29.139 12.5023 29.3021 12.14 29.4656C10.4263 30.2391 8.69184 31.022 6.66663 31.5112C9.01329 34.3125 10.4533 35.4976 12.5333 37.006C14.8266 38.7299 18.0266 38.7837 20.3733 37.1137Z"
        fill="#2058BB"
      />
    </svg>
  );
}
