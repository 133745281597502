import { atom } from 'recoil'

export const kycTokenAtom = atom<string>({
  key: 'kyc:kycTokenAtom',
  default: '',
})
export const kycTextAtom = atom<string>({
  key: 'kyc:kycTextAtom',
  default: '',
})

export default {
  'kyc:token': kycTokenAtom,
  'kyc:text': kycTextAtom,
}
