import { useHistory } from "react-router-dom";

import { useQuery } from "./useQuery";

export const useIsReferralScreen = () => {
  const history = useHistory();
  const query = useQuery();
  const { pathname } = history.location;

  return pathname.includes("/referral") && !query.get("utm_isReferral");
};
