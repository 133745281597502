/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */

interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function InformationIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 3C17.7746 3 3 17.7746 3 36C3 54.2254 17.7746 69 36 69C54.2254 69 69 54.2254 69 36C69 17.7746 54.2254 3 36 3ZM36 21C34.3431 21 33 22.3431 33 24C33 25.6569 34.3431 27 36 27H36.03C37.6869 27 39.03 25.6569 39.03 24C39.03 22.3431 37.6869 21 36.03 21H36ZM39 36C39 34.3431 37.6569 33 36 33C34.3431 33 33 34.3431 33 36V48C33 49.6569 34.3431 51 36 51C37.6569 51 39 49.6569 39 48V36Z"
        fill="#4A5DBF"
      />
    </svg>
  );
}
