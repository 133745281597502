interface Iprops {
  color?: string
  scale?: string
  className?: string
}

export default function ChevronUpIcon({
  color = 'currentColor',
  className,
  scale = '1',
  ...props
}: Iprops) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      width={`${16 * +scale}`}
      height={`${16 * +scale}`}
      fill={color}
      className={className || ''}
      viewBox={`0 0 ${16 * +scale} ${16 * +scale}`}
      {...props}
    >
      <path
        style={{ transform: `scale(${scale})` }}
        fillRule="evenodd"
        d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z"
      />
    </svg>
  )
}
