import Header from "components/header";

type IProps = {
  isLanding: boolean;
};

const TopMainBar = ({ isLanding }: IProps) => (
  <Header
    isLanding={isLanding}
    shadow="0 0 #0000,0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)"
  />
);

export default TopMainBar;
