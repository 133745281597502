/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable jsx-a11y/media-has-caption */

import { useState } from "react";

import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { ChakraButton } from "components/chakra";

interface CampaignDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  executeScroll: () => () => void;
  setIsVisibleTopCampaign: (data: boolean) => void;
}

export const CampaignDetailsModal = ({
  isOpen,
  onClose,
  executeScroll,
  setIsVisibleTopCampaign
}: CampaignDetailsModalProps) => {
  const history = useHistory();
  const [isScrolled, setIsScrolled] = useState<boolean>();
  const { t } = useTranslation();
  const handleScroll = ({ target }: any) => {
    setIsScrolled(target.scrollTop > 0);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: "full", md: "6xl" }}>
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: "none", md: "2.4rem" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        m="auto 0"
        w={{ base: "100%", md: "64rem" }}
        p={{ base: "0", md: "3.2rem 1rem" }}
        pos={{ base: "fixed", md: "relative" }}
        top="0"
        left="0"
      >
        <ModalCloseButton
          pos="absolute"
          w="5.6rem"
          h="5.6rem"
          fontSize="14px"
          top="0"
          right="0"
          variant="unstyled"
          bg="transparent"
          color="rgba(0, 0, 0, 0.36)"
          zIndex="999"
        />
        <ModalBody p="0">
          <Heading
            fontSize={{ base: "2rem", md: "3.2rem" }}
            fontWeight="600"
            lineHeight={{ base: "2.8rem", md: "4rem" }}
            textColor="#0D0D19"
            position="sticky"
            top="0"
            px={{ base: "1.6rem", md: "3.2rem" }}
            mb="1.6rem"
            mt={{ base: "2.4rem", md: "0" }}
          >
            {t("cash_landing.campaigns.title")}
          </Heading>
          <Box
            onScroll={handleScroll}
            overflowY="scroll"
            maxH={{ base: "calc(100vh - 160px)", md: "calc(100vh - 320px)" }}
            className="abb-custom-scrollbar-gray"
            pl={{ base: "1.6rem", md: "3.2rem" }}
            pr="1.6rem"
            font-size="1.4rem"
            lineHeight="2rem"
            fontWeight="400"
            fontSize="1.6rem"
            pos="relative"
            style={{
              maskImage: isScrolled
                ? "linear-gradient(to top, black calc(100% - 60px), transparent 100%)"
                : "unset"
            }}
          >
            <Trans
              i18nKey="cash_landing.campaigns.texts"
              components={{
                p: (
                  <p
                    style={{
                      fontSize: "1.6rem",
                      lineHeight: "2.4rem",
                      color: "#0D0D19"
                    }}
                  />
                ),
                ul: <ul />,
                li: <li />,
                br: <br />,
                a: <a target="_blank" style={{ textDecoration: "underline" }} />
              }}
            />
          </Box>
          <Box
            mx={{ base: "auto", md: "3.2rem" }}
            w={{ base: "100%", md: "unset" }}
            pos={{ base: "fixed", md: "unset" }}
            bottom="0"
            left="0"
            alignItems="center"
            display={{ base: "flex", md: "block" }}
          >
            <ChakraButton
              btnType="primary"
              mt="3.2rem"
              mb={{ base: "1.6rem", md: "0" }}
              mx={{ base: "1.6rem", md: "auto" }}
              w="100%"
              label={t("cash_landing.hero.get_credit")}
              fontSize="1.6rem"
              onClick={() => {
                if (
                  history.location.pathname === "/cash-loan" ||
                  history.location.pathname === "/en/cash-loan" ||
                  history.location.pathname === "/ru/cash-loan"
                ) {
                  executeScroll();
                  onClose();
                  setIsVisibleTopCampaign(false);
                } else {
                  history.replace({
                    pathname: "/cash-loan",
                    search: history.location.search
                  });
                  localStorage.setItem("isScrolled", true);
                  setIsVisibleTopCampaign(false);
                }
              }}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
