/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";

declare global {
  interface Window {
    lintrk?: {
      (action: string, data: { conversion_id?: number }): void;
      q?: [string, { conversion_id?: number }][];
    };
    _linkedin_data_partner_ids?: string[];
  }
}

export const LinkedInTracking = () => {
  useEffect(() => {
    const partnerId = "6343746";

    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(partnerId);

    (function (l) {
      if (!l) {
        window.lintrk = function (a, b) {
          window.lintrk?.q?.push([a, b]);
        };
        window.lintrk.q = [];
      }
      const s = document.getElementsByTagName("script")[0];
      const b = document.createElement("script");
      b.type = "text/javascript";
      b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode?.insertBefore(b, s);
    }(window.lintrk));

    const noscript = document.createElement("noscript");
    const img = document.createElement("img");
    img.height = 1;
    img.width = 1;
    img.style.display = "none";
    img.alt = "";
    img.src = `https://px.ads.linkedin.com/collect/?pid=${partnerId}&fmt=gif`;
    noscript.appendChild(img);
    document.body.appendChild(noscript);
  }, []);

  return null;
};
