/* eslint-disable react/function-component-definition */

export default function HomeIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9773 1.33636C12.3196 1.24368 12.6804 1.24368 13.0227 1.33636C13.4201 1.44395 13.7546 1.7066 14.0216 1.91623C16.325 3.72462 18.6427 5.51471 20.9562 7.31011C21.3329 7.6024 21.6647 7.8599 21.912 8.19421C22.129 8.48759 22.2906 8.8181 22.389 9.1695C22.501 9.56992 22.5006 9.98993 22.5001 10.4667C22.4975 12.924 22.5 15.3813 22.5 17.8386C22.5 18.3657 22.5 18.8204 22.4694 19.195C22.4371 19.5904 22.3658 19.9836 22.173 20.362C21.8854 20.9264 21.4265 21.3854 20.862 21.673C20.4836 21.8658 20.0904 21.9371 19.695 21.9694C18.8935 22.0349 18.0805 21.9996 17.2768 22C17.1577 22 17.0155 22.0001 16.8902 21.9899C16.745 21.978 16.537 21.9475 16.319 21.8365C16.0368 21.6927 15.8073 21.4632 15.6635 21.181C15.5524 20.963 15.522 20.755 15.5101 20.6098C15.4999 20.4845 15.4999 20.3422 15.5 20.2231L15.5079 14.6269C15.5087 14.0661 15.5091 13.7857 15.4002 13.5715C15.3044 13.3831 15.1514 13.2298 14.9631 13.1338C14.749 13.0246 14.4686 13.0246 13.9079 13.0246H11.108C10.5487 13.0246 10.269 13.0246 10.0553 13.1334C9.86724 13.2291 9.71429 13.3818 9.61832 13.5697C9.50922 13.7833 9.50882 14.063 9.50802 14.6223L9.5 20.2231C9.50005 20.3422 9.5001 20.4845 9.48987 20.6098C9.478 20.755 9.44756 20.963 9.33651 21.181C9.1927 21.4632 8.96323 21.6927 8.68098 21.8365C8.46304 21.9475 8.25505 21.978 8.10983 21.9899C7.98451 22.0001 7.84226 22 7.72315 22C6.91946 21.9996 6.10649 22.0349 5.30497 21.9694C4.90963 21.9371 4.51641 21.8658 4.13803 21.673C3.57354 21.3854 3.1146 20.9264 2.82698 20.362C2.63418 19.9836 2.56287 19.5904 2.53057 19.195C2.49996 18.8204 2.49998 18.3657 2.5 17.8385C2.5 15.3812 2.50258 12.9239 2.49993 10.4667C2.49941 9.98993 2.49896 9.56992 2.61104 9.1695C2.7094 8.8181 2.87105 8.48759 3.08804 8.19421C3.3353 7.85991 3.66711 7.6024 4.04374 7.31012C6.35718 5.51479 8.6751 3.72458 10.9784 1.91623C11.2454 1.7066 11.5799 1.44395 11.9773 1.33636Z"
        fill={color}
      />
    </svg>
  );
}
