/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function GlassesIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width={`${32 * +scale}`}
      height={`${32 * +scale}`}
      viewBox={`0 0 ${32 * +scale} ${32 * +scale}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={{ transform: `scale(${scale})` }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4584 23.8294C30.2599 22.6899 30.6626 21.3555 30.6667 20.0201L30.6668 20.0027L30.6667 19.9837C30.6657 19.5675 30.6259 19.1514 30.5475 18.7413L29.7196 10.4621C29.6892 10.158 29.6666 9.93202 29.6302 9.72803C29.2027 7.3368 27.2134 5.53651 24.7915 5.34911C24.5849 5.33312 24.3578 5.33316 24.0522 5.33321L24 5.33321C23.2636 5.33321 22.6667 5.93017 22.6667 6.66655C22.6667 7.40293 23.2636 7.99988 24 7.99988C24.3805 7.99988 24.4959 8.00087 24.5858 8.00783C25.7967 8.10153 26.7914 9.00167 27.0051 10.1973C27.021 10.2861 27.0335 10.4008 27.0713 10.7794L27.421 14.276C25.1826 12.9359 22.3274 13.0244 20.1705 14.5415L29.4584 23.8294Z"
        fill="#2058BB"
      />
      <path
        style={{ transform: `scale(${scale})` }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16178 2.27602C14.6618 12.2977 20.1671 17.8847 29.7119 27.8261C30.2326 28.3468 30.2326 29.1911 29.7119 29.7118C29.1912 30.2325 28.347 30.2325 27.8263 29.7118L24.7401 26.6256C22.7965 26.8414 20.7762 26.2042 19.286 24.7139C18.0424 23.4704 17.3929 21.8578 17.3373 20.2288C16.4741 19.9236 15.5259 19.9236 14.6628 20.2287C14.6072 21.8578 13.9576 23.4704 12.7141 24.7139C10.1106 27.3174 5.88947 27.3174 3.28597 24.7139C1.98938 23.4173 1.33852 21.7195 1.33338 20.0202L1.33325 20.0028L1.33337 19.9836C1.33438 19.5675 1.37411 19.1514 1.45256 18.7413L2.27529 10.514L2.28048 10.4621C2.31084 10.158 2.3334 9.93201 2.36986 9.72803C2.61814 8.33913 3.39333 7.1496 4.47415 6.35968L2.27616 4.16164C1.75546 3.64094 1.75546 2.79672 2.27616 2.27602C2.79686 1.75532 3.64108 1.75532 4.16178 2.27602ZM4.99492 10.1973C4.97904 10.2861 4.96658 10.4007 4.92872 10.7794L4.57906 14.276C7.13981 12.7429 10.5078 13.0795 12.7141 15.2858C13.3879 15.9597 13.8873 16.7419 14.2124 17.5763C14.6245 17.462 15.0453 17.388 15.4688 17.3543L6.40632 8.29185C5.68086 8.66291 5.1451 9.35716 4.99492 10.1973Z"
        fill="#2058BB"
      />
    </svg>
  );
}
