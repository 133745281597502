interface Props {
  color?: string;
  scale?: string;
  className?: string;
  isTimeOut?: boolean;
}

const PlayIcon = ({ ...props }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.63636 1.9475C6.64423 1.95274 6.65212 1.958 6.66004 1.96328L13.6018 6.5911C13.8026 6.72498 13.9889 6.84912 14.1319 6.96447C14.2812 7.08485 14.4571 7.25178 14.5584 7.49599C14.6923 7.81878 14.6923 8.18155 14.5584 8.50433C14.4571 8.74854 14.2812 8.91548 14.1319 9.03586C13.9889 9.1512 13.8027 9.27533 13.6018 9.4092L6.63639 14.0528C6.39087 14.2165 6.17011 14.3637 5.98278 14.4652C5.79532 14.5668 5.53801 14.6829 5.23769 14.665C4.85354 14.642 4.49868 14.4521 4.26651 14.1452C4.085 13.9053 4.03886 13.6268 4.0194 13.4145C3.99996 13.2023 3.99998 12.937 4 12.6419L4 3.38689C4 3.37738 4 3.36789 4 3.35844C3.99998 3.06336 3.99996 2.79803 4.0194 2.58586C4.03886 2.37353 4.085 2.09504 4.26651 1.8551C4.49868 1.5482 4.85354 1.35828 5.23769 1.33534C5.53801 1.31741 5.79532 1.4335 5.98278 1.53509C6.1701 1.63661 6.39086 1.7838 6.63636 1.9475Z"
      fill="white"
    />
  </svg>
  );

  export default PlayIcon;
