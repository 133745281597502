/* eslint-disable react/function-component-definition */

export default function AbbIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <rect width="64" height="64" rx="16" fill="#1963D1" />
      <path
        d="M37.2762 31.6231C38.719 30.8484 39.4301 29.6757 39.4301 28.1263C39.4301 27.6447 39.3463 27.184 39.2001 26.7443C39.0327 26.3047 38.8026 25.9067 38.5099 25.5507C38.2171 25.1947 37.8617 24.9016 37.4643 24.6504C37.0043 24.3781 36.4815 24.1897 35.8961 24.0849C35.3314 23.9804 34.6623 23.9385 33.8885 23.9385H28.7025C27.9705 23.9385 27.4269 24.106 27.0923 24.4409C26.7577 24.776 26.5903 25.3204 26.5903 26.0743V40.25H33.5958C34.4531 40.25 35.185 40.2081 35.8333 40.1244C36.4815 40.0407 37.0462 39.894 37.5481 39.6847C38.3426 39.3287 38.9909 38.7843 39.4719 38.0305C39.9527 37.2976 40.1829 36.4391 40.1829 35.5177C40.1829 33.6543 39.2001 32.3349 37.2762 31.6231ZM30.1243 26.6815H32.6129C33.763 26.6815 34.5785 26.7861 35.1223 27.0373C35.645 27.2887 35.9587 27.7912 35.9587 28.5449C35.9587 28.9009 35.8961 29.236 35.7287 29.5292C35.5197 29.9269 35.185 30.1573 34.7877 30.3039C34.3695 30.4505 33.7422 30.5343 32.9683 30.5343H30.1454V26.6815H30.1243ZM33.4703 37.5279H30.1454V33.1727H33.3658C34.4531 33.1727 35.2686 33.3191 35.8123 33.6751C36.3351 34.0101 36.6279 34.5755 36.6279 35.3293C36.607 36.816 35.5823 37.5279 33.4703 37.5279Z"
        fill="white"
      />
      <path
        d="M13.0815 36.46H19.3131L20.7141 40.25H24.3947L18.7067 25.802C18.5393 25.3832 18.3511 25.0272 18.1631 24.7341C17.9748 24.4411 17.7029 24.1897 17.3892 24.0223C17.0756 23.8337 16.6573 23.75 16.1972 23.75C15.7372 23.75 15.3399 23.8337 15.0053 24.0223C14.6916 24.2107 14.4199 24.4411 14.2316 24.7341C14.0433 25.0272 13.8552 25.3832 13.6879 25.802L8 40.25H11.6804L13.0815 36.46ZM16.1764 27.7493L16.1972 27.8121L16.2183 27.7493L18.3721 33.738H14.0433L16.1764 27.7493Z"
        fill="white"
      />
      <path
        d="M52.9807 31.6231C54.4235 30.8484 55.1345 29.6757 55.1345 28.1263C55.1345 27.6447 55.0509 27.184 54.9045 26.7443C54.7372 26.3047 54.5072 25.9067 54.2144 25.5507C53.9216 25.1947 53.5661 24.9016 53.1689 24.6504C52.7088 24.3781 52.186 24.1897 51.6005 24.0849C51.0359 23.9804 50.3668 23.9385 49.5931 23.9385H44.4071C43.6751 23.9385 43.1313 24.106 42.7968 24.4409C42.4623 24.776 42.2949 25.3204 42.2949 26.0743V40.25H49.3003C50.1576 40.25 50.8895 40.2081 51.5377 40.1244C52.186 40.0407 52.7507 39.894 53.2525 39.6847C54.0472 39.3287 54.6953 38.7843 55.1764 38.0305C55.6573 37.2976 55.8873 36.4391 55.8873 35.5177C55.8873 33.6543 54.9253 32.3349 52.9807 31.6231ZM45.8499 26.6815H48.3384C49.4885 26.6815 50.304 26.7861 50.8476 27.0373C51.3705 27.2887 51.6841 27.7912 51.6841 28.5449C51.6841 28.9009 51.6213 29.236 51.4541 29.5292C51.2451 29.9269 50.9104 30.1573 50.5132 30.3039C50.0949 30.4505 49.4675 30.5343 48.6937 30.5343H45.8708V26.6815H45.8499ZM49.1748 37.5279H45.8499V33.1727H49.0703C50.1576 33.1727 50.9732 33.3191 51.5169 33.6751C52.0396 34.0101 52.3324 34.5755 52.3324 35.3293C52.3115 36.816 51.2869 37.5279 49.1748 37.5279Z"
        fill="white"
      />
    </svg>
  );
}
