import { atom } from 'recoil'

export const applicationIdAtom = atom<string>({
  key: 'cashLoan:application:applicationIdAtom',
  default: '',
})

export const processNameAtom = atom<string>({
  key: 'cashLoan:application:processNameAtom',
  default: '', // 'OFFER'
})
export interface ICustomerInfo {
  firstName: string
  lastName: string
  patronymic: string
}
export const customerInfoAtom = atom<ICustomerInfo | {}>({
  key: 'cashLoan:application:customerInfoAtom',
  default: {},
})

export default {
  'cashLoan:application:applicationIdAtom': applicationIdAtom,
  'cashLoan:application:processNameAtom': processNameAtom,
  'cashLoan:application:customerInfoAtom': customerInfoAtom,
}
