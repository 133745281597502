/* eslint-disable react/function-component-definition */
export default function SecurityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.571 1.63265C17.8555 1.59153 18.1445 1.59153 18.4291 1.63265C18.757 1.68003 19.0622 1.79532 19.3045 1.88685L19.3694 1.91132L27.6081 5.0006C28.5348 5.34658 29.3486 5.65042 29.9753 6.20275C30.5236 6.68593 30.9459 7.29537 31.2058 7.97839C31.5029 8.75915 31.5017 9.62783 31.5002 10.617L31.5 17.9998C31.5 22.2397 29.1941 25.7746 26.7014 28.3714C24.1921 30.9856 21.321 32.8321 19.8029 33.7178L19.7414 33.7538C19.4644 33.9165 19.1048 34.1276 18.6291 34.2296C18.2399 34.3131 17.7602 34.3131 17.371 34.2296C16.8953 34.1276 16.5357 33.9165 16.2587 33.7538L16.1972 33.7178C14.679 32.8321 11.808 30.9856 9.29862 28.3714C6.80597 25.7746 4.50003 22.2397 4.50003 17.9998L4.49983 10.617C4.4984 9.62784 4.49714 8.75915 4.79424 7.97839C5.05415 7.29537 5.47649 6.68593 6.02476 6.20275C6.6515 5.65042 7.46531 5.34658 8.39199 5.0006L16.6306 1.91132L16.6956 1.88685C16.9379 1.79532 17.243 1.68003 17.571 1.63265ZM24.3107 14.5607C24.8964 13.9749 24.8964 13.0251 24.3107 12.4393C23.7249 11.8536 22.7751 11.8536 22.1893 12.4393L16.5 18.1287L14.5607 16.1893C13.9749 15.6036 13.0251 15.6036 12.4393 16.1893C11.8536 16.7751 11.8536 17.7249 12.4393 18.3107L15.4393 21.3107C16.0251 21.8964 16.9749 21.8964 17.5607 21.3107L24.3107 14.5607Z"
        fill="#2058BB"
      />
    </svg>
  );
}
