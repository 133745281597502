import { ReactNode } from "react";

import { Flex } from "@chakra-ui/react";

import Footer from "components/footer";
import TopMenu from "layout/TopMenu";

export const withoutNavLayoutStep = 10; // just for indicating the layout without nav bar

const PageWrapper = ({ children, showIcon }: { children: ReactNode, showIcon?: boolean }) => (
  <Flex direction="column" h="100%" pos="relative">
    <TopMenu isLanding={false} />
    <Flex mt="7.4rem" flex="1 1 0%">
      <Flex direction={{ base: "column", md: "row" }} w="100%">
        <Flex
          direction="column"
          overflow="hidden"
          flex="1 1 0%"
          justifyContent="center"
          pt={{ base: "1.6rem", md: "4rem" }}
          bg="#f5f6f7"
          w="100%"
        >
          <Flex px="1.6rem" justifyContent="center" mb="auto" w="100%">
            {children}
          </Flex>
          <Footer isLanding={false} {...{ showIcon }} />
        </Flex>
      </Flex>
    </Flex>
  </Flex>
);

export default PageWrapper;
