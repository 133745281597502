interface IColorFields {
  label: string
  value: string
}
interface Color {
  main: IColorFields
  tints: IColorFields[]
  shades: IColorFields[]
}

const COLORS: Color[] = [
  {
    main: {
      label: 'primary',
      value: '#1b57ec',
    },
    tints: [
      {
        label: 'l-5',
        value: '#3368ee',
      },
      {
        label: 'l-10',
        value: '#4a7af0',
      },
      {
        label: 'l-15',
        value: '#628bf2',
      },
    ],
    shades: [
      {
        label: 'd-5',
        value: '#124cdb',
      },
      {
        label: 'd-10',
        value: '#1044c4',
      },
      {
        label: 'd-15',
        value: '#0e3cac',
      },
    ],
  },
  {
    main: {
      label: 'secondary',
      value: '#fb239d',
    },
    tints: [
      {
        label: 'l-5',
        value: '#fb3ca8',
      },
      {
        label: 'l-10',
        value: '#fc55b3',
      },
      {
        label: 'l-15',
        value: '#fc6ebe',
      },
    ],
    shades: [
      {
        label: 'd-5',
        value: '#fb0a92',
      },
      {
        label: 'd-10',
        value: '#e70484',
      },
      {
        label: 'd-15',
        value: '#ce0476',
      },
    ],
  },
]

export default COLORS
