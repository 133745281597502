/* eslint-disable no-nested-ternary */
export const LOCAL_STORAGE_LANG_KEY = "ABB_SELECTED_LANG";

export type OptionType = {
  value: string;
  label: string;
};

export const OPTIONS: OptionType[] = [
  { label: "AZ", value: "az" },
  { label: "EN", value: "en" },
  { label: "RU", value: "ru" }
];

export const MOBILE_OPTIONS: OptionType[] = [
  { label: "Az", value: "az" },
  { label: "En", value: "en" },
  { label: "Ru", value: "ru" }
];

export const getCustomeStyles = () => ({
  control: (baseStyles) => ({
    ...baseStyles,
    border: "none",
    boxShadow: "none",
    background: "none",
    fontSize: "14px",
    fontWeight: 500,
    height: "36px",
    width: "60px",
    borderRadius: 0,
    justifyContent: "center",
    padding: "0 10px",
    cursor: "pointer"
  }),
  indicatorSeparator: () => ({
    display: "none"
  }),
  indicatorsContainer: (baseStyles) => ({
    ...baseStyles,
    padding: 0
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    color: "rgb(26, 32, 44)!important",
    padding: 0
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    justifyContent: "center",
    padding: 0
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    margin: 0
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    margin: 0,
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "2px 4px 16px 0px rgba(0, 0, 0, 0.04)"
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    border: "none",
    borderRadius: "8px"
  }),
  option: (baseStyles, { isSelected }) => ({
    ...baseStyles,
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
    alignItems: "center",
    display: "flex",
    paddingLeft: "20px",
    color: "#000",
    backgroundColor: isSelected ? "#EBEBF2!important" : "inherit",
    ":hover": {
      backgroundColor: "#EBEBF2"
    },
    ":active": {
      backgroundColor: "#EBEBF2"
    }
  })
});
