import application from "./application";
import contracts from "./contracts";
import disbursement from "./disbursement";
import idUpload from "./idUpload";
import kyc from "./kyc";
import language from "./language";
import loan from "./loan";
import offer from "./offer";
import otp from "./otp";
import personalInfo from "./personalInfo";
import returnToFunnel from "./returnToFunnel";
import steps from "./steps";

export default {
  ...steps,
  ...loan,
  ...personalInfo,
  ...otp,
  ...idUpload,
  ...contracts,
  ...disbursement,
  ...offer,
  ...application,
  ...kyc,
  ...returnToFunnel,
  ...language
};
