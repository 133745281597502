/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Props {
  color?: string;
  scale?: string;
  className?: string;
}

export default function RefinanceBankNoteIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Props) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0002 14C14.8956 14 14.0002 14.8955 14.0002 16C14.0002 17.1046 14.8956 18 16.0002 18C17.1047 18 18.0002 17.1046 18.0002 16C18.0002 14.8955 17.1047 14 16.0002 14Z"
        fill="#00A400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.88211 5.33338L25.1182 5.33338C25.8211 5.33335 26.4274 5.33333 26.9269 5.37414C27.454 5.41721 27.9783 5.5123 28.4828 5.76935C29.2354 6.15285 29.8474 6.76477 30.2309 7.51742C30.4879 8.02192 30.583 8.54622 30.6261 9.07334C30.6669 9.57276 30.6669 10.1791 30.6668 10.882V21.1181C30.6669 21.821 30.6669 22.4273 30.6261 22.9268C30.583 23.4539 30.4879 23.9782 30.2309 24.4827C29.8474 25.2353 29.2354 25.8472 28.4828 26.2307C27.9783 26.4878 27.454 26.5829 26.9269 26.626C26.4275 26.6668 25.8211 26.6667 25.1183 26.6667L6.88192 26.6667C6.17915 26.6667 5.57284 26.6668 5.07346 26.626C4.54634 26.5829 4.02204 26.4878 3.51754 26.2307C2.76489 25.8472 2.15297 25.2353 1.76947 24.4827C1.51242 23.9782 1.41733 23.4539 1.37426 22.9267C1.33346 22.4273 1.33348 21.821 1.3335 21.1181V10.8819C1.33348 10.1791 1.33346 9.57274 1.37426 9.07334C1.41733 8.54621 1.51242 8.02192 1.76947 7.51742C2.15297 6.76477 2.76489 6.15285 3.51754 5.76935C4.02205 5.51229 4.54634 5.41721 5.07346 5.37414C5.57287 5.33333 6.17928 5.33335 6.88211 5.33338ZM11.3335 16C11.3335 13.4227 13.4228 11.3334 16.0002 11.3334C18.5775 11.3334 20.6668 13.4227 20.6668 16C20.6668 18.5774 18.5775 20.6667 16.0002 20.6667C13.4228 20.6667 11.3335 18.5774 11.3335 16ZM8.00016 12C8.73654 12 9.3335 12.597 9.3335 13.3334V18.6667C9.3335 19.4031 8.73654 20 8.00016 20C7.26378 20 6.66683 19.4031 6.66683 18.6667V13.3334C6.66683 12.597 7.26378 12 8.00016 12ZM25.3335 13.3334C25.3335 12.597 24.7365 12 24.0002 12C23.2638 12 22.6668 12.597 22.6668 13.3334V18.6667C22.6668 19.4031 23.2638 20 24.0002 20C24.7365 20 25.3335 19.4031 25.3335 18.6667V13.3334Z"
        fill="#00A400"
      />
    </svg>
  );
}
