import { useHistory } from "react-router-dom";

export const useIsRootPage = () => {
  const history = useHistory();
  const { pathname } = history.location;
  const rootPaths = ["/", "/referral", ""];
  const pathWithoutLocale = pathname.replace("/en", "").replace("/ru", "");

  return rootPaths.includes(pathWithoutLocale);
};
