/* eslint-disable react/function-component-definition */
export default function TagIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.76936 3.51767C2.15286 2.76502 2.76478 2.1531 3.51743 1.76961C4.02193 1.51255 4.54622 1.41746 5.07335 1.37439C5.57278 1.33359 6.17914 1.33361 6.882 1.33363L13.0105 1.33356C13.553 1.33306 14.0313 1.33261 14.4959 1.44415C14.9041 1.54214 15.2943 1.70376 15.6521 1.92307C16.0595 2.17272 16.3974 2.51126 16.7807 2.89524L27.123 13.2376C27.8819 13.9965 28.5083 14.6229 28.9769 15.1748C29.4635 15.7481 29.8577 16.3281 30.0822 17.0189C30.4302 18.09 30.4302 19.2439 30.0822 20.3151C29.8577 21.0058 29.4635 21.5859 28.9769 22.1591C28.5083 22.711 27.8819 23.3374 27.123 24.0963L24.0961 27.1232C23.3371 27.8822 22.7108 28.5085 22.1589 28.9771C21.5856 29.4638 21.0055 29.858 20.3148 30.0824C19.2436 30.4304 18.0898 30.4305 17.0186 30.0824C16.3279 29.858 15.7478 29.4638 15.1746 28.9771C14.6226 28.5085 13.9963 27.8822 13.2374 27.1232L2.895 16.781C2.51102 16.3977 2.17247 16.0598 1.92283 15.6524C1.70351 15.2945 1.54189 14.9043 1.44391 14.4962C1.33237 14.0316 1.33281 13.5532 1.33332 13.0107L1.33339 6.88224C1.33336 6.17937 1.33334 5.57302 1.37415 5.07359C1.41722 4.54647 1.5123 4.02218 1.76936 3.51767ZM10.6667 8.00016C9.19399 8.00016 8.00008 9.19407 8.00008 10.6668C8.00008 12.1396 9.19399 13.3335 10.6667 13.3335C12.1395 13.3335 13.3334 12.1396 13.3334 10.6668C13.3334 9.19407 12.1395 8.00016 10.6667 8.00016Z"
        fill="#3B8DD4"
      />
    </svg>
  );
}
