import { ReactNode } from "react";

import { Box, BoxProps } from "@chakra-ui/react";

import TopBar from "components/header/TopBar";
import TopCampaign from "pages/RootLanding/components/TopCampaign";

interface IProps extends BoxProps {
  children?: ReactNode;
  isLanding: boolean;
  setIsVisibleTopCampaign: (data: boolean) => void;
  isVisible: boolean;
  executeScroll?: any;
}
const Header = ({
  children,
  isLanding,
  setIsVisibleTopCampaign,
  isVisible,
  executeScroll,
  ...rest
}: IProps) => (
  <>
    <Box opacity={isVisible ? "1" : "0"}>
      <TopCampaign
        isVisible={isVisible}
        setIsVisibleTopCampaign={setIsVisibleTopCampaign}
        executeScroll={executeScroll}
      />
    </Box>
    <TopBar isLanding={isLanding} {...rest}>
      {children}
    </TopBar>
  </>
);

export default Header;
