import { Box, BoxProps, Flex, Image, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import logoBlue from "assets/logoBlue.svg";
import FooterIcon from "components/icons/FooterIcon";

interface IProps extends BoxProps {
  isLanding: boolean;
  showIcon?: boolean;
}
const Footer = ({ isLanding, showIcon = true }: IProps) => {
  const { t } = useTranslation();
  if (isLanding) {
    return (
      <Box
        w="100%"
        px={{ base: "1.6rem", sm: "3.2rem", md: "6rem", lg: "12rem" }}
        borderColor="rgba(228, 228, 228, 1)"
        borderTopWidth={{ md: "1px" }}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          maxW="120rem"
          justifyContent="space-between"
          mx="auto"
          pos="relative"
          w="100%"
        >
          <Flex
            justifyContent="center"
            bg="white"
            h="6.5rem"
            mb={{ base: "4.4rem", md: "0" }}
            alignItems="center"
          >
            <Image h="3.2rem" src={logoBlue} alt="logo" />
          </Flex>
          <Flex align="center" justifyContent="center">
            <Link
              href="https://www.facebook.com/abbbank.az"
              target="_blank"
              w="3rem"
              h="3rem"
              mr="0.6rem"
              borderRadius="50%"
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              bg="#F0F0F0"
              rel="noreferrer"
            >
              <svg
                width="9"
                height="17"
                viewBox="0 0 9 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.39673 6V4C5.39673 3.448 5.84473 3 6.39673 3H7.39673V0.5H5.39673C3.73973 0.5 2.39673 1.843 2.39673 3.5V6H0.396729V8.5H2.39673V16.5H5.39673V8.5H7.39673L8.39673 6H5.39673Z"
                  fill="#2058BB"
                />
              </svg>
            </Link>
            <Link
              href="https://www.instagram.com/abb_bank/"
              target="_blank"
              w="3rem"
              h="3rem"
              mr="0.6rem"
              borderRadius="50%"
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              bg="#F0F0F0"
              rel="noreferrer"
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.28562 0.5C2.13817 0.5 0.396729 2.24144 0.396729 4.38889V10.6111C0.396729 12.7586 2.13817 14.5 4.28562 14.5H10.5078C12.6553 14.5 14.3967 12.7586 14.3967 10.6111V4.38889C14.3967 2.24144 12.6553 0.5 10.5078 0.5H4.28562ZM12.0634 2.05556C12.4927 2.05556 12.8412 2.404 12.8412 2.83333C12.8412 3.26267 12.4927 3.61111 12.0634 3.61111C11.6341 3.61111 11.2856 3.26267 11.2856 2.83333C11.2856 2.404 11.6341 2.05556 12.0634 2.05556ZM7.39673 3.61111C9.54417 3.61111 11.2856 5.35256 11.2856 7.5C11.2856 9.64744 9.54417 11.3889 7.39673 11.3889C5.24928 11.3889 3.50784 9.64744 3.50784 7.5C3.50784 5.35256 5.24928 3.61111 7.39673 3.61111ZM7.39673 5.16667C6.77789 5.16667 6.1844 5.4125 5.74681 5.85008C5.30923 6.28767 5.0634 6.88116 5.0634 7.5C5.0634 8.11884 5.30923 8.71233 5.74681 9.14992C6.1844 9.5875 6.77789 9.83333 7.39673 9.83333C8.01557 9.83333 8.60906 9.5875 9.04665 9.14992C9.48423 8.71233 9.73006 8.11884 9.73006 7.5C9.73006 6.88116 9.48423 6.28767 9.04665 5.85008C8.60906 5.4125 8.01557 5.16667 7.39673 5.16667Z"
                  fill="#2058BB"
                />
              </svg>
            </Link>
            <Link
              href="https://www.youtube.com/c/abbaz"
              target="_blank"
              w="3rem"
              h="3rem"
              mr="0.6rem"
              borderRadius="50%"
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              bg="#F0F0F0"
              rel="noreferrer"
            >
              <svg
                width="17"
                height="12"
                viewBox="0 0 17 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.1202 2.29395C15.9244 1.44304 15.2284 0.815264 14.3905 0.721618C12.4064 0.500136 10.3982 0.49898 8.39929 0.500136C6.40001 0.49898 4.39148 0.500136 2.4074 0.721618C1.57003 0.815264 0.87454 1.44304 0.678659 2.29395C0.399867 3.50574 0.396729 4.82852 0.396729 6.07615C0.396729 7.32378 0.396729 8.64639 0.675521 9.85801C0.871072 10.7088 1.56673 11.3365 2.40459 11.4303C4.38851 11.652 6.39687 11.6531 8.39615 11.652C10.3958 11.6531 12.4036 11.652 14.3874 11.4303C15.2247 11.3367 15.9209 10.7089 16.1168 9.85801C16.3957 8.64622 16.3967 7.32361 16.3967 6.07615C16.3967 4.82852 16.399 3.50574 16.1202 2.29395ZM6.33196 8.55951C6.33196 6.78964 6.33196 5.03827 6.33196 3.26824C8.02768 4.15301 9.71216 5.03183 11.4186 5.92205C9.71744 6.80434 8.03214 7.67788 6.33196 8.55951Z"
                  fill="#2058BB"
                />
              </svg>
            </Link>
            <Link
              href="https://twitter.com/abb_bank"
              target="_blank"
              w="3rem"
              h="3rem"
              mr="0.6rem"
              borderRadius="50%"
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              bg="#F0F0F0"
              rel="noreferrer"
            >
              <svg
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.3967 1.94283C14.8446 2.18801 14.2516 2.35301 13.6291 2.42743C14.2648 2.04667 14.7523 1.44381 14.9819 0.724992C14.3877 1.07748 13.7289 1.33362 13.0274 1.47208C12.4661 0.873832 11.6665 0.5 10.7815 0.5C9.08197 0.5 7.70433 1.87822 7.70433 3.57719C7.70433 3.81833 7.73202 4.05371 7.78394 4.27812C5.22654 4.15005 2.95932 2.92471 1.44092 1.06248C1.1767 1.51708 1.02497 2.04552 1.02497 2.6103C1.02497 3.67757 1.56784 4.61965 2.39338 5.17117C1.88917 5.15501 1.41438 5.01656 0.99959 4.78637C0.99959 4.79964 0.99959 4.81176 0.99959 4.82503C0.99959 6.31631 2.05993 7.56011 3.46815 7.84222C3.21027 7.9126 2.93798 7.9501 2.65703 7.9501C2.45915 7.9501 2.26589 7.93048 2.07839 7.89529C2.47011 9.11775 3.60661 10.0079 4.95309 10.0327C3.90025 10.8583 2.57338 11.3504 1.13112 11.3504C0.883056 11.3504 0.637873 11.3359 0.396729 11.3071C1.75879 12.1799 3.37584 12.6893 5.11405 12.6893C10.7746 12.6893 13.8691 8.00029 13.8691 3.93371C13.8691 3.80045 13.8662 3.66776 13.8604 3.53565C14.4621 3.10125 14.9842 2.55954 15.3967 1.94283Z"
                  fill="#2058BB"
                />
              </svg>
            </Link>
            <Link
              href="https://www.linkedin.com/company/the-international-bank-of-azerbaijan/"
              target="_blank"
              w="3rem"
              h="3rem"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              bg="#F0F0F0"
              borderRadius="50%"
              rel="noreferrer"
            >
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.30173 1.95833C3.30173 2.76392 2.65423 3.41667 1.85506 3.41667C1.0559 3.41667 0.408395 2.76392 0.408395 1.95833C0.408395 1.15333 1.0559 0.5 1.85506 0.5C2.65423 0.5 3.30173 1.15333 3.30173 1.95833ZM3.3134 4.58333H0.396729V13.9167H3.3134V4.58333ZM7.96956 4.58333H5.07156V13.9167H7.97014V9.01725C7.97014 6.29308 11.4871 6.07025 11.4871 9.01725V13.9167H14.3967V8.00692C14.3967 3.41025 9.19223 3.57767 7.96956 5.84042V4.58333Z"
                  fill="#2058BB"
                />
              </svg>
            </Link>
          </Flex>
        </Flex>
      </Box>
    );
  }

  return (
    <Flex
      p="2.4rem"
      bg={{ base: "#f5f6f7", md: "transparent" }}
      direction={{ base: "column", md: "row" }}
      w="100%"
      justifyContent={{ base: "center", md: "flex-start" }}
      alignItems="center"
    >
      {showIcon && (
        <Box mr={{ md: "2.4rem" }} mb={{ base: "2.4rem", md: 0 }}>
          <FooterIcon />
        </Box>
      )}
      <Text
        color="rgba(0, 0, 0, 0.48)"
        fontWeight={400}
        fontSize="1.4rem"
        lineHeight="1.7rem"
      >
        © ABB -
        {" "}
        {t("footer_title")}
      </Text>
    </Flex>
  );
};

export default Footer;
