/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef } from "react";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList
} from "@chakra-ui/react";
import { map } from "lodash-es";
import { Trans, useTranslation } from "react-i18next";

import { ChevronDownIcon, ChevronUpIcon } from "components/icons";

import { useGetFaqList } from "./useGetFaqList";

interface Props {
  isTamkart?: boolean;
  isMainLanding?: boolean;
}

export const FAQ = forwardRef<HTMLDivElement, Props>(
  ({ isTamkart, isMainLanding = false }, ref) => {
    const { t } = useTranslation();

    const faqList = useGetFaqList(isTamkart);
    const cashFaqList = useGetFaqList(false);
    const tamKartFaqList = useGetFaqList(true);

    return (
      <Box
        ref={ref}
        pos="relative"
        mx="auto"
        mt={{ md: "9.8rem", base: "6.4rem" }}
        maxW="104rem"
        w="100%"
      >
        <Flex flexDirection="column" justifyContent="center" pos="relative">
          {(isTamkart && !isMainLanding) || (!isTamkart && !isMainLanding) ? (
            <>
              <Heading
                mb={{ base: "2.4rem", md: "3.2rem" }}
                fontFamily="Inter"
                fontWeight="600"
                lineHeight="1.15"
                textAlign="left"
                fontSize={{ base: "2.4rem", md: "3.2rem" }}
                color="black"
              >
                {t("cash_landing.menu.faq_mobile")}
              </Heading>

              <Accordion allowToggle>
                {map(faqList, (faq) => (
                  <AccordionItem
                    borderRadius="1rem"
                    bgColor="#fff"
                    border="none"
                    mb="0.8rem"
                    px="2.8rem"
                  >
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton
                          fontWeight={500}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          bgColor="transparent!important"
                          textAlign="left"
                          outline="none"
                          w="100%"
                          px="0"
                          fontSize={{ sm: "1.8rem" }}
                          py={{ base: "1.6rem", sm: "3rem" }}
                        >
                          <Text>{t(faq?.titleLangKey)}</Text>
                          <AccordionIcon
                            color={isExpanded ? "#1485FF" : "initial"}
                            as={ChevronUpIcon}
                            scale="1.5"
                          />
                        </AccordionButton>

                        <AccordionPanel p={0}>
                          <UnorderedList
                            m={0}
                            pl="3rem"
                            pb="2rem"
                            className="list-disc abb-custom-bullets"
                          >
                            {map(
                              t(faq.answersLangKey, {
                                returnObjects: true
                              }) as string[] as React.ReactNode[],
                              (langKey, index: number) => (
                                <ListItem key={`faq-${index}`}>
                                  <Trans
                                    i18nKey={langKey as string}
                                    components={{
                                      a: (
                                        <a
                                          target="blank"
                                          style={{ color: "rgb(32,88,187)" }}
                                        />
                                      ),
                                      sup: <sup />
                                    }}
                                  />
                                </ListItem>
                              )
                            )}
                          </UnorderedList>
                          {faq?.descriptionLangKey && (
                            <Text mb="2rem">
                              <Trans
                                i18nKey={faq.descriptionLangKey as string}
                                components={{
                                  br: <br />,
                                  sup: <sup />,
                                  a: (
                                    <a
                                      target="blank"
                                      style={{ color: "rgb(32,88,187)" }}
                                    />
                                  )
                                }}
                              />
                            </Text>
                          )}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
            </>
          ) : (
            <Tabs>
              <Flex
                flexDirection={{ md: "row", base: "column" }}
                alignItems={{ base: "unset", md: "center" }}
                w="100%"
                justifyContent="space-between"
                mb={{ base: "0", md: "2.4rem" }}
              >
                <Heading
                  mb={{ base: "2.4rem", md: "0" }}
                  fontFamily="Inter"
                  fontWeight={600}
                  lineHeight="1.15"
                  textAlign="left"
                  fontSize={{ base: "2.4rem", md: "3.2rem" }}
                  color="black"
                >
                  {t("cash_landing.menu.faq_mobile")}
                </Heading>
                <TabList
                  display="flex"
                  bg="#E1E1EB"
                  borderRadius="1.6rem"
                  paddingX="1.2rem"
                  paddingY="1.2rem"
                  mb="0.8rem"
                  w={{ base: "100%", md: "328px" }}
                >
                  <Tab
                    fontSize="1.6rem"
                    fontWeight="500"
                    lineHeight="2.4rem"
                    textColor="#0D0D19"
                    bg="transparent"
                    mr="0.8rem"
                    px="1.6rem"
                    h="4.8rem"
                    w="100%"
                    borderRadius="1rem"
                    _selected={{ color: "white", bg: "#2058BB" }}
                  >
                    {t("cash_offer.tamkartCrossSell.cashLoan")}
                  </Tab>
                  <Tab
                    fontSize="1.6rem"
                    fontWeight="500"
                    lineHeight="2.4rem"
                    textColor="#0D0D19"
                    bg="transparent"
                    mr="0.8rem"
                    px="1.6rem"
                    h="4.8rem"
                    w="100%"
                    borderRadius="1rem"
                    _selected={{ color: "white", bg: "#2058BB" }}
                  >
                    Tamkart
                  </Tab>
                </TabList>
              </Flex>

              <TabPanels>
                <TabPanel px="0">
                  <Accordion allowToggle>
                    {map(cashFaqList, (faq) => (
                      <AccordionItem
                        borderRadius="1rem"
                        bgColor="#fff"
                        border="none"
                        mb="0.8rem"
                        px="1.6rem"
                        key={faq.answersLangKey}
                      >
                        {({ isExpanded }) => (
                          <>
                            <AccordionButton
                              fontWeight={500}
                              display="flex"
                              alignItems="center"
                              bgColor="transparent!important"
                              textAlign="left"
                              outline="none"
                              w="100%"
                              px="0"
                              fontSize={{ sm: "1.8rem" }}
                              py={{ base: "1.6rem", sm: "3rem" }}
                              justifyContent={{
                                base: "unset",
                                md: "space-between"
                              }}
                            >
                              <AccordionIcon
                                color="initial"
                                as={ChevronDownIcon}
                                mr="0.8rem"
                                display={{ base: "block", md: "none" }}
                              />
                              <Text>{t(faq?.titleLangKey)}</Text>
                              <AccordionIcon
                                color="initial"
                                as={ChevronDownIcon}
                                mr="0.8rem"
                                display={{ base: "none", md: "block" }}
                              />
                            </AccordionButton>

                            <AccordionPanel p={0}>
                              <UnorderedList
                                m={0}
                                pl="3rem"
                                pb="2rem"
                                className="list-disc abb-custom-bullets"
                              >
                                {map(
                                  t(faq.answersLangKey, {
                                    returnObjects: true
                                  }) as string[] as React.ReactNode[],
                                  (langKey, index: number) => (
                                    <ListItem key={`faq-${index}`}>
                                      <Trans
                                        i18nKey={langKey as string}
                                        components={{
                                          a: (
                                            <a
                                              target="blank"
                                              style={{
                                                color: "rgb(32,88,187)"
                                              }}
                                            />
                                          ),
                                          sup: <sup />
                                        }}
                                      />
                                    </ListItem>
                                  )
                                )}
                              </UnorderedList>
                              {faq?.descriptionLangKey && (
                                <Text mb="2rem">
                                  <Trans
                                    i18nKey={faq.descriptionLangKey as string}
                                    components={{
                                      br: <br />,
                                      sup: <sup />,
                                      a: (
                                        <a
                                          target="blank"
                                          style={{ color: "rgb(32,88,187)" }}
                                        />
                                      )
                                    }}
                                  />
                                </Text>
                              )}
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    ))}
                  </Accordion>
                </TabPanel>
                <TabPanel px="0">
                  <Accordion allowToggle>
                    {map(tamKartFaqList, (faq) => (
                      <AccordionItem
                        borderRadius="1rem"
                        bgColor="#fff"
                        border="none"
                        mb="0.8rem"
                        px="1.6rem"
                        key={faq.answersLangKey}
                      >
                        {({ isExpanded }) => (
                          <>
                            <AccordionButton
                              fontWeight={500}
                              display="flex"
                              alignItems="center"
                              bgColor="transparent!important"
                              textAlign="left"
                              outline="none"
                              w="100%"
                              px="0"
                              fontSize={{ sm: "1.8rem" }}
                              py={{ base: "1.6rem", sm: "3rem" }}
                              justifyContent={{
                                base: "unset",
                                md: "space-between"
                              }}
                            >
                              <AccordionIcon
                                color="initial"
                                as={ChevronDownIcon}
                                mr="0.8rem"
                                display={{ base: "block", md: "none" }}
                              />
                              <Text>{t(faq?.titleLangKey)}</Text>
                              <AccordionIcon
                                color="initial"
                                as={ChevronDownIcon}
                                mr="0.8rem"
                                display={{ base: "none", md: "block" }}
                              />
                            </AccordionButton>

                            <AccordionPanel p={0}>
                              <UnorderedList
                                m={0}
                                pl="3rem"
                                pb="2rem"
                                className="list-disc abb-custom-bullets"
                              >
                                {map(
                                  t(faq.answersLangKey, {
                                    returnObjects: true
                                  }) as string[] as React.ReactNode[],
                                  (langKey, index: number) => (
                                    <ListItem key={`faq-${index}`}>
                                      <Trans
                                        i18nKey={langKey as string}
                                        components={{
                                          a: (
                                            <a
                                              target="blank"
                                              style={{
                                                color: "rgb(32,88,187)"
                                              }}
                                            />
                                          ),
                                          sup: <sup />
                                        }}
                                      />
                                    </ListItem>
                                  )
                                )}
                              </UnorderedList>
                              {faq?.descriptionLangKey && (
                                <Text mb="2rem">
                                  <Trans
                                    i18nKey={faq.descriptionLangKey as string}
                                    components={{
                                      br: <br />,
                                      sup: <sup />,
                                      a: (
                                        <a
                                          target="blank"
                                          style={{ color: "rgb(32,88,187)" }}
                                        />
                                      )
                                    }}
                                  />
                                </Text>
                              )}
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    ))}
                  </Accordion>
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </Flex>
      </Box>
    );
  }
);
