/* eslint-disable jsx-a11y/media-has-caption */
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useMediaQuery
} from "@chakra-ui/react";

interface Props {
  videoUrl: string;
  isOpen: boolean;
  onClose: () => void;
}

export const VideoModal = ({ videoUrl, isOpen, onClose }: Props) => {
  const [isMobileScreen] = useMediaQuery("(max-width: 768px)");

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent
        backgroundColor="transparent"
        boxShadow="none"
        maxWidth="fit-content"
        h="100%"
      >
        <ModalBody
          padding={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          maxH="100%"
        >
          <Box pos="relative">
            <video
              width={isMobileScreen ? "300px" : "400px"}
              height={isMobileScreen ? "300px" : "400px"}
              controls
              autoPlay
              controlsList="nodownload"
              style={{
                borderRadius: "8px",
                overflow: "hidden"
              }}
            >
              <source src={videoUrl} type="video/mp4" />
            </video>

            <ModalCloseButton
              size="xl"
              color="white"
              pos="absolute"
              right="10px"
              top="10px"
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
