import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const instance = i18n.createInstance();

instance
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?${+new Date()}`
    },
    ns: ["translation"],
    defaultNS: "translation",
    returnObjects: true,
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false
    },
    // load: 'all',
    // react: {
    //   wait: true,
    // },
    detection: {
      caches: ["cookie"],
      lookupCookie: "lang",
      cookieMinutes: 525949 // 1 year.
    },
    supportedLngs: ["az", "en", "ru"],
    fallbackLng: "az"
  });

export default i18n;
