/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function CheckIconGreen({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0002 3.3335C19.7497 3.3335 3.3335 19.7497 3.3335 40.0002C3.3335 60.2506 19.7497 76.6668 40.0002 76.6668C60.2506 76.6668 76.6668 60.2506 76.6668 40.0002C76.6668 19.7497 60.2506 3.3335 40.0002 3.3335ZM57.3572 32.3572C58.6589 31.0554 58.6589 28.9449 57.3572 27.6431C56.0554 26.3414 53.9449 26.3414 52.6431 27.6431L35.0002 45.2861L27.3572 37.6431C26.0554 36.3414 23.9449 36.3414 22.6431 37.6431C21.3414 38.9449 21.3414 41.0554 22.6431 42.3572L32.6431 52.3572C33.9449 53.6589 36.0554 53.6589 37.3572 52.3572L57.3572 32.3572Z"
        fill="#00A400"
      />
    </svg>
  );
}
