/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function CrossSellCardIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.118 5.33326L6.88193 5.33325C6.17911 5.33323 5.57268 5.33321 5.07328 5.37402C4.54615 5.41708 4.02186 5.51217 3.51736 5.76923C2.76471 6.15272 2.15279 6.76465 1.76929 7.51729C1.51223 8.0218 1.41715 8.54609 1.37408 9.07322C1.33328 9.57261 1.33329 10.179 1.33331 10.8818C1.33331 11.4993 1.83391 11.9999 2.45144 11.9999H29.5486C30.1661 11.9999 30.6666 11.4993 30.6666 10.8818C30.6667 10.179 30.6667 9.57264 30.6259 9.07322C30.5828 8.54609 30.4877 8.0218 30.2307 7.5173C29.8472 6.76465 29.2353 6.15273 28.4826 5.76923C27.9781 5.51217 27.4538 5.41709 26.9267 5.37402C26.4273 5.33321 25.8209 5.33323 25.118 5.33326Z"
        fill="#3B8DD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.06133 14.8119C2.34654 14.6666 2.71991 14.6666 3.46665 14.6666H28.5333C29.2801 14.6666 29.6534 14.6666 29.9386 14.8119C30.1895 14.9397 30.3935 15.1437 30.5213 15.3946C30.6666 15.6798 30.6666 16.0532 30.6666 16.7999V21.118C30.6667 21.8209 30.6667 22.4272 30.6259 22.9266C30.5828 23.4538 30.4877 23.978 30.2307 24.4826C29.8472 25.2352 29.2353 25.8471 28.4826 26.2306C27.9781 26.4877 27.4538 26.5828 26.9267 26.6258C26.4273 26.6666 25.8209 26.6666 25.1182 26.6666L6.8817 26.6666C6.17897 26.6666 5.57264 26.6666 5.07328 26.6258C4.54615 26.5828 4.02186 26.4877 3.51736 26.2306C2.76471 25.8471 2.15279 25.2352 1.76929 24.4826C1.51223 23.978 1.41715 23.4538 1.37408 22.9266C1.33328 22.4272 1.33329 21.8208 1.33331 21.118V16.7999C1.33331 16.0532 1.33331 15.6798 1.47864 15.3946C1.60647 15.1437 1.81044 14.9397 2.06133 14.8119ZM7.99998 18.6666C7.2636 18.6666 6.66665 19.2635 6.66665 19.9999C6.66665 20.7363 7.2636 21.3333 7.99998 21.3333H14.6666C15.403 21.3333 16 20.7363 16 19.9999C16 19.2635 15.403 18.6666 14.6666 18.6666H7.99998Z"
        fill="#3B8DD4"
      />
    </svg>
  );
}
