interface Props {
  color?: string;
  scale?: string;
  className?: string;
}

const CrossSellClockIcon = ({ ...props }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.70711 3.70711C6.09763 3.31658 6.09763 2.68342 5.70711 2.29289C5.31658 1.90237 4.68342 1.90237 4.29289 2.29289L1.29289 5.29289C0.902369 5.68342 0.902369 6.31658 1.29289 6.70711C1.68342 7.09763 2.31658 7.09763 2.70711 6.70711L5.70711 3.70711Z"
      fill="#3B8DD4"
    />
    <path
      d="M19.7071 2.29289C19.3166 1.90237 18.6834 1.90237 18.2929 2.29289C17.9024 2.68342 17.9024 3.31658 18.2929 3.70711L21.2929 6.70711C21.6834 7.09763 22.3166 7.09763 22.7071 6.70711C23.0976 6.31658 23.0976 5.68342 22.7071 5.29289L19.7071 2.29289Z"
      fill="#3B8DD4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C14.0512 22 16.0302 21.2998 17.6175 20.0317L19.2929 21.7071C19.6834 22.0976 20.3166 22.0976 20.7071 21.7071C21.0976 21.3166 21.0976 20.6834 20.7071 20.2929L19.0317 18.6175C20.2998 17.0302 21 15.0512 21 13C21 10.6131 20.0518 8.32387 18.364 6.63604C16.6761 4.94821 14.3869 4 12 4C9.61305 4 7.32387 4.94821 5.63604 6.63604C3.94821 8.32387 3 10.6131 3 13C3 15.0512 3.70023 17.0302 4.96832 18.6175L3.29289 20.2929C2.90237 20.6834 2.90237 21.3166 3.29289 21.7071C3.68342 22.0976 4.31658 22.0976 4.70711 21.7071L6.38253 20.0317C7.96977 21.2998 9.94879 22 12 22ZM16.2071 11.7071C16.5976 11.3166 16.5976 10.6834 16.2071 10.2929C15.8166 9.90237 15.1834 9.90237 14.7929 10.2929L11 14.0858L9.70711 12.7929C9.31658 12.4024 8.68342 12.4024 8.29289 12.7929C7.90237 13.1834 7.90237 13.8166 8.29289 14.2071L10.2929 16.2071C10.6834 16.5976 11.3166 16.5976 11.7071 16.2071L16.2071 11.7071Z"
      fill="#3B8DD4"
    />
  </svg>
);

export default CrossSellClockIcon;
