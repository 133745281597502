/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function BankIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2785 2.81622C15.7368 2.6166 16.2639 2.6166 16.7223 2.81622L29.5558 8.37195C30.3579 8.71975 30.8048 9.58781 30.6272 10.4443C30.4553 11.3066 29.7048 11.8737 28.7824 11.9258V12.3887C28.7824 13.1584 28.2209 13.7776 27.4074 13.7776H4.4903C3.78216 13.7776 3.11528 13.1584 3.11528 12.3887V11.9258C2.29657 11.8737 1.54592 11.3066 1.37088 10.4443C1.19586 9.58781 1.64452 8.71975 2.44438 8.37195L15.2785 2.81622ZM16.0004 10.0739C17.0145 10.0739 17.8338 9.24637 17.8338 8.22207C17.8338 7.1995 17.0145 6.37021 16.0004 6.37021C14.9863 6.37021 14.167 7.1995 14.167 8.22207C14.167 9.24637 14.9863 10.0739 16.0004 10.0739ZM8.61537 23.7776H10.9071V15.6295H14.5738V23.7776H17.3754V15.6295H21.0421V23.7776H23.3339V15.6295H27.0006V24.0265C27.035 24.0438 27.0693 24.0149 27.1037 24.0901L29.8538 25.942C30.5241 26.3934 30.822 27.2383 30.5871 28.0195C30.3522 28.8008 29.6418 29.3332 28.834 29.3332H3.11528C2.35901 29.3332 1.64618 28.8008 1.41208 28.0195C1.17793 27.2383 1.47757 26.3934 2.1499 25.942L4.89994 24.0901C4.9326 24.0149 4.9664 24.0438 4.94864 24.0265V15.6295H8.61537V23.7776Z"
        fill="#3B8DD4"
      />
    </svg>
  );
}
