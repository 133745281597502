import { useEffect } from "react";

import { Box, BoxProps, Flex } from "@chakra-ui/react";
import ReactDOM from "react-dom";
import FocusLock from "react-focus-lock";

interface Props extends BoxProps {}

export const LoadingSpinner = ({
  topSpacing = "default"
}: {
  topSpacing?: "sm" | "default";
}) => (
  <Flex pos="relative" alignItems="center" justifyContent="center" zIndex={10}>
    <Box
      flexDir="column"
      display="inline-flex"
      alignItems="center"
      bg="white"
      borderRadius="50%"
      p="2.2rem"
      m="auto"
      borderWidth="2px"
      borderColor="rgb(246, 247, 248)"
      mt={topSpacing === "sm" ? "10vh" : "40vh"}
    >
      <Box display="inline" className="animate-spin">
        <svg
          width="51"
          height="52"
          viewBox="0 0 51 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.0002 2C31.0813 2 36.9358 4.30849 41.3804 8.45895C45.825 12.6094 48.5283 18.2924 48.944 24.3593C49.3597 30.4263 47.4569 36.4248 43.62 41.1428C39.783 45.8607 34.2982 48.9462 28.2739 49.7757C22.2495 50.6052 16.1349 49.1168 11.1657 45.6114C6.19652 42.106 2.7433 36.8449 1.50391 30.8914"
            stroke="url(#paint0_linear)"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="25"
              y1="26"
              x2="2"
              y2="26"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#5289DA" />
              <stop offset="1" stopColor="#5289DA" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </Box>
    </Box>
  </Flex>
);

const Loading = ({ ...rest }: Props) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return ReactDOM.createPortal(
    <FocusLock>
      <Box pos="fixed" cursor="wait" zIndex={50} inset={0}>
        <Box pos="fixed" bg="rgb(0,0,0)" inset={0} opacity={0.5} {...rest} />
        <LoadingSpinner />
      </Box>
    </FocusLock>,
    document.body
  );
};

export default Loading;
