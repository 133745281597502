/* eslint-disable no-unused-vars */

import { useEffect, useRef, useState } from "react";

import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { ChakraButton } from "components/chakra";

import { CampaignDetailsModal } from "./CampaignDetailsModal";

interface Props {
  isVisible: boolean;
  setIsVisibleTopCampaign: (data: boolean) => void;
  executeScroll?: () => void;
}

const TopCampaign = ({
  isVisible,
  setIsVisibleTopCampaign,
  executeScroll
}: Props) => {
  const { t } = useTranslation();

  const [isOpenModal, setModalVisibility] = useState(false);
  const history = useHistory();

  const closeCampaign = () => {
    setIsVisibleTopCampaign(false);
  };

  const openDetailsModal = () => {
    setModalVisibility(true);
  };

  const [height, setHeight] = useState();
  const ref = useRef<any>(null);

  useEffect(() => {
    setHeight(ref?.current?.clientHeight);
  }, [ref]);

  return (
    <>
      <Flex
        ref={ref}
        pos="fixed"
        zIndex="999"
        top={isVisible ? "0" : `-${height}px`}
        left="0"
        py={{ base: "2.4rem", md: "3.2rem" }}
        px={{ base: "1.6rem", md: "4.8rem" }}
        w="100%"
        borderRadius="0px 0px 24px 24px"
        bg="linear-gradient(225deg, #52A7D7 0%, #2849AC 50%, #7E1BBB 100%)"
        transition="all 0.8s ease-out"
      >
        <Box
          pos="absolute"
          right={{ base: "8px", md: "16px" }}
          top={{ base: "8px", md: "16px" }}
          cursor="pointer"
          onClick={closeCampaign}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5858L6.70711 5.29289C6.31658 4.90237 5.68342 4.90237 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L13.4142 12L18.7071 6.70711Z"
              fill="white"
              fillOpacity="0.72"
            />
          </svg>
        </Box>
        <Flex
          maxW="104rem"
          margin="0 auto"
          justifyContent="space-between"
          flexDir={{ base: "column", md: "column", lg: "row" }}
          textAlign={{ base: "center", md: "center", lg: "left" }}
          w="100%"
          alignItems="center"
        >
          <Box>
            <Heading
              textColor="#fff"
              fontSize={{ base: "1.8rem", md: "2rem" }}
              fontWeight="500"
              lineHeight="2.8rem"
            >
              {`${t("cash_landing.campaigns.title")}!`}
            </Heading>
            <Text
              textColor="#fff"
              fontSize={{ base: "1.4rem", md: "1.6rem" }}
              fontWeight="400"
              lineHeight={{ base: "2rem", md: "2.4rem" }}
            >
              {t("cash_landing.campaigns.subtitle")}
            </Text>
          </Box>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            pos="relative"
            mt={{ base: "2.4rem", lg: "0" }}
            flexDir={{ base: "column", md: "column", lg: "row" }}
          >
            {/* <CountdownView /> */}
            <Flex>
              <ChakraButton
                textColor="#000000"
                label={t("cash_landing.hero.get_credit")}
                btnType="white"
                fontSize="1.4rem"
                fontWeight="500"
                lineHeight="2rem"
                h="4rem"
                w="15rem"
                mr="0.8rem"
                onClick={() => {
                  if (
                    history.location.pathname === "/cash-loan" ||
                    history.location.pathname === "/en/cash-loan" ||
                    history.location.pathname === "/ru/cash-loan"
                  ) {
                    executeScroll?.();
                    setIsVisibleTopCampaign(false);
                  } else {
                    history.replace({
                      pathname: "/cash-loan",
                      search: history.location.search
                    });
                    localStorage.setItem("isScrolled", true);
                    setIsVisibleTopCampaign(false);
                  }
                }}
              />
              <Button
                fontWeight="500"
                lineHeight="2rem"
                h="4rem"
                w="auto"
                px="1.6rem"
                border="1px solid rgba(255, 255, 255, 0.72)"
                bg="transparent"
                textColor="#fff"
                variant="unstyled"
                borderRadius="8px"
                fontSize="14px"
                onClick={openDetailsModal}
              >
                {t("cash_landing.hero.details")}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <CampaignDetailsModal
        isOpen={isOpenModal}
        onClose={() => setModalVisibility(false)}
        executeScroll={executeScroll}
        setIsVisibleTopCampaign={setIsVisibleTopCampaign}
      />
    </>
  );
};

export default TopCampaign;
