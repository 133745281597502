import { useEffect } from "react";

export const useResetScroll = () => {
  useEffect(() => {
    const appNode = document.getElementById("abb-app-wrapper");
    if (appNode) {
      appNode.scrollTop = 0;
    }
  }, []);
};
