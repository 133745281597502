import type { ReactNode } from "react";

import { Flex, Stack, StackProps } from "@chakra-ui/react";
import { Controller, UseControllerProps } from "react-hook-form";

import { Radio } from "../Radio";

interface Option {
  label: string;
  value: string;
}

interface CustomRadioGroupBaseProps extends UseControllerProps {
  stackProps?: StackProps;
  onChange?: (value: string) => void;
}

interface CustomRadioGroupWithChildren extends CustomRadioGroupBaseProps {
  children: ReactNode;
  options?: never;
  radioDescription?: ReactNode;
}

interface CustomRadioGroupWithOptions extends CustomRadioGroupBaseProps {
  options: Option[];
  children?: never;
  radioDescription?: ReactNode;
}

type CustomRadioGroupProps =
  | CustomRadioGroupWithChildren
  | CustomRadioGroupWithOptions;

export const RadioGroup = ({
  control,
  name,
  rules,
  options,
  stackProps,
  children,
  onChange,
  radioDescription
}: CustomRadioGroupProps) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    render={({ field }) => {
      const handleChange = (value: string) => {
        field.onChange(value);
        if (onChange) {
          onChange(value);
        }
      };

      return (
        <Stack {...stackProps}>
          {children ||
            options?.map((option) => (
              <Flex direction="column" gap="12px">
                <Radio
                  key={option.value}
                  value={option.value}
                  isChecked={field.value === option.value}
                  onChange={() => handleChange(option.value)}
                >
                  {option.label}
                </Radio>
                {field.value === option.value && radioDescription}
              </Flex>
            ))}
        </Stack>
      );
    }}
  />
);
