import { atom } from 'recoil'

export const activeStepPathAtom = atom<number[]>({
  key: 'cashLoan:activeStepPathAtom',
  default: [0, 0], // offer-[1, 2], videoKyc-[2, 4]
})

export default {
  'cashLoan:activeStepPathAtom': activeStepPathAtom,
}
