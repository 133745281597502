/* eslint-disable react/jsx-no-useless-fragment */
import { StrictMode, Suspense } from "react";

import { ChakraProvider } from "@chakra-ui/react";
import { LoadScriptNext } from "@react-google-maps/api";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";

import "assets/fonts/SF-Pro-Black.otf";
import "assets/fonts/SF-Pro-Bold.otf";
import "assets/fonts/SF-Pro-Heavy.otf";
import "assets/fonts/SF-Pro-Light.otf";
import "assets/fonts/SF-Pro-Medium.otf";
import "assets/fonts/SF-Pro-Regular.otf";
import "assets/fonts/SF-Pro-Semibold.otf";
import "assets/fonts/SF-Pro-Thin.otf";
import "assets/fonts/SF-Pro-Ultralight.otf";

import "./index.css";
import "./tailwind.css";

import Loading from "components/loading";
import $GlobalStyle from "styleguide/$GlobalStyle";

// eslint-disable-next-line no-unused-vars
import "i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import customeTheme from "./theme";

const tagManagerArgs = {
  gtmId: "GTM-WDX78T3"
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById("root");

const root = createRoot(container as Element);

root.render(
  <StrictMode>
    <$GlobalStyle />
    <LoadScriptNext
      googleMapsApiKey="AIzaSyBOErPiv-g9xx8MQLRDQjo-DqjPa5CIu9w"
      loadingElement={<></>}
    >
      <RecoilRoot>
        <BrowserRouter>
          <Suspense fallback={<Loading opacity={0} />}>
            <ChakraProvider theme={customeTheme}>
              <App />
            </ChakraProvider>
          </Suspense>
        </BrowserRouter>
      </RecoilRoot>
    </LoadScriptNext>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
