/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function ScrollDownArrowIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66699 3.33341C8.66699 2.96522 8.36852 2.66675 8.00033 2.66675C7.63214 2.66675 7.33366 2.96522 7.33366 3.33341V11.0573L3.80506 7.52868C3.54471 7.26833 3.1226 7.26833 2.86225 7.52868C2.6019 7.78903 2.6019 8.21114 2.86225 8.47149L7.52892 13.1382C7.78927 13.3985 8.21138 13.3985 8.47173 13.1382L13.1384 8.47149C13.3987 8.21114 13.3987 7.78903 13.1384 7.52868C12.878 7.26833 12.4559 7.26833 12.1956 7.52868L8.66699 11.0573V3.33341Z"
        fill="#2058BB"
      />
    </svg>
  );
}
