import { useMediaQuery } from "@chakra-ui/react";

export const useGetScreenSize = () => {
  const [isLargerThan640] = useMediaQuery("(min-width: 640px)");
  const [isSmallerThan992] = useMediaQuery("(max-width: 992px)");
  const [isMobileScreen] = useMediaQuery("(max-width: 640px)");

  const isTabletScreen = isLargerThan640 && isSmallerThan992;

  return { isMobileScreen, isTabletScreen };
};
